// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  overflow-y: hidden;
}

.body_container_newA {
  margin-top: 100px;
  margin-right: 80px;
  display: flex;
  height: 80vh;
}
.body_container_newA_images {
  width: 50%;
  height: 100%;
  overflow-y: auto;
}
.body_container_newA_images_opt {
  display: flex;
  justify-content: space-between;
}
.body_container_newA_preview {
  height: 90vh;
  margin-left: 32px;
  width: 40%;
  right: 5%;
  position: fixed;
}

.buttonBorderRad {
  background: #ffffff !important;
  border: 1px solid #7024d1 !important;
  color: #7024d1 !important;
}

.centeredContainer {
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/images/style.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAGE;EACE,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;AAAJ;AAEI;EACE,UAAA;EACA,YAAA;EACA,gBAAA;AAAN;AAEM;EACE,aAAA;EACA,8BAAA;AAAR;AAII;EACE,YAAA;EACA,iBAAA;EACA,UAAA;EACA,SAAA;EACA,eAAA;AAFN;;AAOA;EACE,8BAAA;EACA,oCAAA;EACA,yBAAA;AAJF;;AAOA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAJF","sourcesContent":["body {\n  overflow-y: hidden;\n}\n\n.body_container {\n  &_newA {\n    margin-top: 100px;\n    margin-right: 80px;\n    display: flex;\n    height: 80vh;\n\n    &_images {\n      width: 50%;\n      height: 100%;\n      overflow-y: auto;\n\n      &_opt {\n        display: flex;\n        justify-content: space-between;\n      }\n    }\n\n    &_preview {\n      height: 90vh;\n      margin-left: 32px;\n      width: 40%;\n      right: 5%;\n      position: fixed;\n    }\n  }\n}\n\n.buttonBorderRad {\n  background: #ffffff !important;\n  border: 1px solid #7024d1 !important;\n  color: #7024d1 !important;\n}\n\n.centeredContainer {\n  height: 70vh;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
