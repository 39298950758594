import { MVTLayer, TileLayer } from "@deck.gl/geo-layers";
import { BitmapLayer } from "@deck.gl/layers";
import { bbox as turfBbox } from "@turf/turf";
import { scaleLinear, scaleThreshold } from "d3-scale";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import LateralMenu from "../../components/lateralMenu/lateralMenu";
import Map from "../../components/map/map";
import MenuMap from "../../components/menuMap/menuMap";
import CustomSnackbar from "../../components/snackbar";
import { fetchData, fetchKPISData, getNumberPages, getProjectByUser, getXyzFolder } from "../../utils/requests";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { navigation } from "../../utils/navigation";
import { useDispatch } from "react-redux";
import { useAppSlice } from "../../slice";

const SERVER = process.env.REACT_APP_MARTIN;
const APPSERVER = process.env.REACT_APP_SERVER_API_DOTGIS;

const COLOR_SCALE_ASPECT = scaleThreshold()
  .domain([45, 135, 225, 315, 360])
  .range([
    [141, 90, 153],
    [250, 232, 35],
    [251, 22, 12],
    [60, 234, 17],
    [141, 90, 153],
  ]);

const COLOR_SCALE_SLOPE = scaleThreshold()
  .domain([5, 15, 25, 50, 86])
  .range([
    [26, 150, 65],
    [166, 217, 106],
    [230, 255, 192],
    [253, 174, 97],
    [215, 25, 28],
  ]);

const COLOR_SCALE_NDVI = scaleThreshold()
  .domain([0.3, 0.5, 0.6, 0.7, 0.8, 1])
  .range([
    [251, 22, 12],
    [239, 69, 17],
    [239, 171, 25],
    [26, 150, 65],
    [21, 225, 72],
    [124, 237, 96],
  ]);

const COLOR_SCALE_NIGHT_TIME_LIGHTS = scaleThreshold()
  .domain([5, 10, 20, 30, 40, 50, 70, 75])
  .range([
    [0, 0, 0],
    [36, 31, 0],
    [73, 62, 0],
    [109, 93, 0],
    [146, 124, 0],
    [182, 155, 0],
    [219, 186, 0],
    [255, 213, 0],
  ]);

const COLOR_SCALE_VEGETATION_HEALTH = scaleLinear()
  .domain([-1, -0.01, 0.17, 0.33, 0.49, 0.66, 0.83, 1])
  .range([
    [215, 25, 28],
    [240, 124, 74],
    [254, 201, 129],
    [196, 230, 135],
    [119, 195, 92],
    [91, 174, 61],
    [26, 150, 65],
  ]);

const COLOR_SCALE_HUMIDITY = scaleThreshold()
  .domain([-0.5, -0.2, 0, 0.15, 0.3, 0.5, 1])
  .range([
    [35, 145, 36],
    [154, 204, 151],
    [210, 252, 205],
    [213, 242, 255],
    [32, 144, 251],
    [11, 51, 236],
    [5, 25, 135],
  ]);

const COLOR_SCALE_WATER_BODIES = scaleThreshold()
  .domain([0.3, 0.5, 1.0])
  .range([
    [42, 185, 238],
    [66, 73, 177],
    [48, 18, 59],
  ]);

const COLOR_SCALE_EARTHWORK_MONITORING = scaleThreshold()
  .domain([1, 2, 3, 4])
  .range([
    [178, 223, 138],
    [27, 160, 0],
    [150, 150, 150],
    [39, 191, 229],
    [255, 235, 133],
  ]);

function Dashboard() {
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useDispatch();
  const { actions } = useAppSlice();

  const [dataResultsMap, setDataResultsMap] = useState({});
  const [openLoader, setOpenLoader] = useState(false);

  const [snackbarProps, setSnackbarProps] = useState({ message: "", severity: "" });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const lateralMenuRef = useRef(null);

  const baseAltitudeGroups = Array.from({ length: 11 }, (_value, index) => index).map((index) => {
    return ((2500 / 10) * index).toFixed(1);
  });

  const baseRadiationGroups = Array.from({ length: 6 }, (_value, index) => index).map((index) => {
    return ((1500000 / 10) * index).toFixed(1);
  });

  const [listProject, setListProject] = useState([]);

  const [listDataset, setListDataset] = useState({});

  const [optionSelectKPI, setOptionSelectKPI] = useState("");

  const [initialMapBbox, setInitialMapBbox] = useState({ bbox: null, zoom: null });

  const [aoi, setAoi] = useState(null);

  const [area, setArea] = useState(0);

  const [numberPages, setNumberPages] = useState(1);
  const [page, setPage] = useState(1);

  const handleChangeSelect = (e) => {
    const { value } = e.target;
    setOptionSelectKPI(value);
  };

  useEffect(() => {
    const getKpis = async () => {
      const projectDataset = optionSelectKPI.split(":");
      if (listDataset[parseFloat(projectDataset[1])]) {
        await fetchKPISData(projectDataset[1], projectDataset[0])
          .then(function (response) {
            if (response.status === 200) {
              setDataResultsMap((previous) => {
                return {
                  ...previous,
                  [projectDataset[0]]: response.data,
                };
              });
            }
          })
          .catch(function (err) {
            setSnackbarProps({
              message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
              severity: "error",
            });
            setOpenSnackbar(true);
          });
      }
    };
    optionSelectKPI && getKpis();
  }, [optionSelectKPI]);

  useEffect(() => {
    if (Object.keys(listDataset).length > 0) {
      const [idProject, datasets] = Object.entries(listDataset)[0];
      const idDataset = datasets[0].id_dataset;
      setOptionSelectKPI(`${idDataset}:${idProject}`);
    } else {
      setOptionSelectKPI("");
    }
  }, [listDataset]);

  const [layersArray, setLayersArray] = useState([]);

  const handleChangeTransparency = async (event, id_project, id_dataset, name_attribute, subimage) => {
    event.stopPropagation();

    listDataset[id_project].forEach((dataset) => {
      if (dataset.id_dataset === id_dataset) {
        dataset.transparency = event.target.value;
      }
    });

    const layerId = getMVTName(name_attribute, id_dataset, id_project);

    if (layerId !== "") {
      const layerNoSelect = layersArray.filter((layer) => layer.id !== layerId);

      setLayersArray(layerNoSelect);

      const layer = await getMVTLayer(name_attribute, id_project, id_dataset, event.target.value, undefined, subimage);

      const checkedLayers = [];
      listDataset[id_project].forEach((dataset) => {
        if (dataset.id_dataset === id_dataset && dataset.checked) {
          checkedLayers.push(layer);
        }
      });
      setLayersArray((oldArray) => [...oldArray, ...checkedLayers]);
    }
  };

  const handleChangeTransparencyXyz = async (event, id_project, id_dataset) => {
    event.stopPropagation();

    const layerId = `xyz${id_dataset}_${id_project}`;

    if (layerId !== "") {
      const layerNoSelect = layersArray.filter((layer) => layer.id !== layerId);

      setLayersArray(layerNoSelect);

      if (!layersArray.some((layer) => layer.id === layerId)) {
        const layer = await getXyzLayer(id_project, id_dataset);
        if (layer) setLayersArray((oldArray) => [layer, ...oldArray]);
      }
    }
  };

  const getMVTName = (name_attribute, id_dataset, id_project) => {
    let layerId = "";
    if (name_attribute === "Radiation") {
      layerId = `mvtRadiation_${id_dataset}_${id_project}`;
    } else if (name_attribute === "Aspect") {
      layerId = `mvtAspect_${id_dataset}_${id_project}`;
    } else if (name_attribute === "Slope (Pitch)") {
      layerId = `mvtSlope_${id_dataset}_${id_project}`;
    } else if (name_attribute === "NDVI") {
      layerId = "mvtNDVI";
    } else if (name_attribute === "Altitude") {
      layerId = `mvtAltitude_${id_dataset}_${id_project}`;
    } else if (name_attribute === "Watersheds") {
      layerId = `mvtWatersheds${id_dataset}_${id_project}`;
    } else if (name_attribute === "Solar Panels") {
      layerId = `mvtSolarPanels${id_dataset}_${id_project}`;
    } else if (name_attribute === "Building Footprints") {
      layerId = `mvtBuildingFootprints${id_dataset}_${id_project}`;
    } else if (name_attribute === "Height") {
      layerId = `mvtHeight${id_dataset}_${id_project}`;
    } else if (name_attribute === "Vegetation health") {
      layerId = `mvtVegetationHealth${id_dataset}_${id_project}`;
    } else if (name_attribute === "Powerblocks") {
      layerId = `mvtPowerblocks${id_dataset}_${id_project}`;
    } else if (name_attribute === "Roads (Solar Plants)") {
      layerId = `mvtRoads${id_dataset}_${id_project}`;
    } else if (name_attribute === "Solar Panels Plants") {
      layerId = `mvtSolarPanelsPlants${id_dataset}_${id_project}`;
    } else if (name_attribute === "Solar Trackers Plants") {
      layerId = `mvtSolarTrackersPlants${id_dataset}_${id_project}`;
    } else if (name_attribute === "Wind Turbines") {
      layerId = `mvtWindTurbines${id_dataset}_${id_project}`;
    } else if (name_attribute === "Humidity") {
      layerId = `mvtHumidity${id_dataset}_${id_project}`;
    } else if (name_attribute === "Water bodies") {
      layerId = `mvtWaterBodies${id_dataset}_${id_project}`;
    } else if (name_attribute === "Segmentation") {
      layerId = `mvtSegmentation${id_dataset}_${id_project}`;
    } else if (name_attribute === "Rooftop Type") {
      layerId = `mvtRooftopType${id_dataset}_${id_project}`;
    } else if (name_attribute === "Vegetation height") {
      layerId = `mvtVegetationHeight_${id_dataset}_${id_project}`;
    } else if (name_attribute === "Monitoring") {
      layerId = `mvtMonitoring_${id_dataset}_${id_project}`;
    } else if (name_attribute === "Burned areas") {
      layerId = `mvtBurnedareas${id_dataset}_${id_project}`;
    } else if (name_attribute === "Night time lights") {
      layerId = `mvtNightTimeLights_${id_dataset}_${id_project}`;
    } else if (name_attribute === "Contour Lines") {
      layerId = `mvtContourLines_${id_dataset}_${id_project}`;
    } else if (name_attribute === "Vehicles") {
      layerId = `mvtVehicles_${id_dataset}_${id_project}`;
    } else if (name_attribute === "Roads") {
      layerId = `mvtRoads_${id_dataset}_${id_project}`;
    } else if (name_attribute === "Earthworks Monitoring") {
      layerId = `mvtEarthworksMonitoring_${id_dataset}_${id_project}`;
    } else if (name_attribute === "High Tension Towers") {
      layerId = `mvtHighTensionTowers_${id_dataset}_${id_project}`;
    } else if (name_attribute === "Medium Tension Towers") {
      layerId = `mvtMediumTensionTowers_${id_dataset}_${id_project}`;
    } else if (name_attribute === "Trenches") {
      layerId = `mvtTrenches_${id_dataset}_${id_project}`;
    }
    return layerId;
  };

  const getMVTLayer = async (name_attribute, id_project, id_dataset, transparency, optionalParameters, subimage) => {
    if (name_attribute === "Radiation") {
      const domainGroups = optionalParameters?.kpisGroups
        ? optionalParameters.kpisGroups
        : dataResultsMap[id_dataset].groups;

      const COLOR_SCALE_RADIATION = scaleThreshold()
        .domain(domainGroups)
        .range([
          [59, 27, 0],
          [93, 42, 0],
          [171, 86, 3],
          [198, 105, 5],
          [237, 165, 33],
          [250, 198, 53],
          [255, 230, 70],
        ]);
      return new MVTLayer({
        id: `mvtRadiation_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_radiation/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: subimage !== "DTM" ? 0.7 : 2,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.radiation !== null && f.properties.radiation > 0) {
            return COLOR_SCALE_RADIATION(f.properties.radiation);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.radiation !== null && f.properties.radiation > 0) {
            return [0, 0, 0, 1];
          }
        },
      });
    } else if (name_attribute === "Aspect") {
      return new MVTLayer({
        id: `mvtAspect_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_aspect/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: subimage !== "DTM" ? 0.7 : 2,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.aspect !== null && f.properties.aspect > 0) {
            return COLOR_SCALE_ASPECT(f.properties.aspect);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.aspect !== null && f.properties.aspect > 0) {
            return [0, 0, 0, 1];
          }
        },
      });
    } else if (name_attribute === "Slope (Pitch)") {
      return new MVTLayer({
        id: `mvtSlope_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_slope/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: subimage !== "DTM" ? 0.7 : 2,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.slope !== null && f.properties.slope > 0) {
            return COLOR_SCALE_SLOPE(f.properties.slope);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.slope !== null && f.properties.slope > 0) {
            return [0, 0, 0, 1];
          }
        },
      });
    } else if (name_attribute === "NDVI") {
      return new MVTLayer({
        id: "mvtNDVI",
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_ndvi/{z}/{x}/{y}.pbf`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: subimage !== "DTM" ? 0.7 : 2,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.ndvi !== null && f.properties.ndvi > 0 && f.properties.ndvi <= 1) {
            return COLOR_SCALE_NDVI(f.properties.ndvi);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.ndvi !== null && f.properties.ndvi > 0 && f.properties.ndvi <= 1) {
            return [0, 0, 0, 1];
          }
        },
      });
    } else if (name_attribute === "Altitude") {
      const domainGroups = optionalParameters?.kpisGroups
        ? optionalParameters.kpisGroups
        : dataResultsMap[id_dataset].groups;

      const COLOR_SCALE_ALTITUDE = scaleThreshold()
        .domain(domainGroups)
        .range([
          [0, 102, 51],
          [0, 153, 51],
          [0, 204, 102],
          [153, 255, 102],
          [255, 255, 102],
          [255, 204, 102],
          [255, 153, 51],
          [204, 102, 0],
          [153, 102, 51],
          [102, 51, 0],
        ]);
      return new MVTLayer({
        id: `mvtAltitude_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_altitude/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: subimage !== "DTM" ? 0.7 : 2,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.altitude !== null && f.properties.altitude > 0) {
            return COLOR_SCALE_ALTITUDE(f.properties.altitude);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.altitude !== null && f.properties.altitude > 0) {
            return [0, 0, 0, 1];
          }
        },
      });
    } else if (name_attribute === "Vegetation height") {
      const domainGroups = optionalParameters?.kpisGroups
        ? optionalParameters.kpisGroups
        : dataResultsMap[id_dataset].groups;

      const COLOR_SCALE_ALTITUDE = scaleThreshold()
        .domain(domainGroups)
        .range([
          [0, 102, 51],
          [0, 153, 51],
          [0, 204, 102],
          [153, 255, 102],
          [255, 255, 102],
          [255, 204, 102],
          [255, 153, 51],
          [204, 102, 0],
          [153, 102, 51],
          [102, 51, 0],
        ]);
      return new MVTLayer({
        id: `mvtVegetationHeight_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_vegetation_height/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: subimage !== "DTM" ? 0.7 : 2,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.vegetation_height !== null && f.properties.vegetation_height > 0) {
            return COLOR_SCALE_ALTITUDE(f.properties.vegetation_height);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.vegetation_height !== null && f.properties.vegetation_height > 0) {
            return [0, 0, 0, 1];
          }
        },
      });
    } else if (name_attribute === "Watersheds") {
      return new MVTLayer({
        id: `mvtWatersheds${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_watersheds/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209, 0],
        getLineWidth: 0.5,
        getLineColor: [112, 39, 209],
        //lineWidthUnits: "pixels",
      });
    } else if (name_attribute === "Solar Panels") {
      return new MVTLayer({
        id: `mvtSolarPanels${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_solar_panels/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209],
        getLineWidth: 0.1,
        getLineColor: [0, 0, 0],
      });
    } else if (name_attribute === "Building Footprints") {
      return new MVTLayer({
        id: `mvtBuildingFootprints${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_building_footprints/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209, 0],
        getLineWidth: 2,
        getLineColor: [112, 39, 209],
        lineWidthUnits: "pixels",
      });
    } else if (name_attribute === "Rooftop Type") {
      return new MVTLayer({
        id: `mvtRooftopType${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_building_rooftop_type/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209, 0],
        getLineWidth: 2,
        getLineColor: [112, 39, 209],
        lineWidthUnits: "pixels",
      });
    } else if (name_attribute === "Height") {
      const domainGroups = optionalParameters?.kpisGroups
        ? optionalParameters.kpisGroups
        : dataResultsMap[id_dataset].groups;

      const COLOR_SCALE_ALTITUDE = scaleThreshold()
        .domain(domainGroups)
        .range([
          [0, 102, 51],
          [0, 153, 51],
          [0, 204, 102],
          [153, 255, 102],
          [255, 255, 102],
          [255, 204, 102],
          [255, 153, 51],
          [204, 102, 0],
          [153, 102, 51],
          [102, 51, 0],
        ]);
      return new MVTLayer({
        id: `mvtHeight${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_height/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: subimage !== "DTM" ? 0.7 : 2,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.height !== null && f.properties.height > 0) {
            return COLOR_SCALE_ALTITUDE(f.properties.height);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.height !== null && f.properties.height > 0) {
            return [0, 0, 0, 1];
          }
        },
      });
    } else if (name_attribute === "Vegetation health") {
      return new MVTLayer({
        id: `mvtVegetationHealth${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_vegetation_health/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: subimage !== "DTM" ? 0.7 : 2,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.ndvi !== null) {
            return COLOR_SCALE_VEGETATION_HEALTH(f.properties.ndvi);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.ndvi !== null) {
            return [0, 0, 0, 1];
          }
        },
      });
    } else if (name_attribute === "Powerblocks") {
      return new MVTLayer({
        id: `mvtPowerblocks${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_powerblocks/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209, 0],
        getLineWidth: 2,
        getLineColor: [112, 39, 209],
        lineWidthUnits: "pixels",
      });
    } else if (name_attribute === "Roads (Solar Plants)") {
      return new MVTLayer({
        id: `mvtRoads${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_solar_panels_plants_roads/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209, 0],
        getLineWidth: 2,
        getLineColor: [112, 39, 209],
        lineWidthUnits: "pixels",
      });
    } else if (name_attribute === "Solar Panels Plants") {
      return new MVTLayer({
        id: `mvtSolarPanelsPlants${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_solar_panels_plants/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209, 0],
        getLineWidth: 2,
        getLineColor: [112, 39, 209],
        lineWidthUnits: "pixels",
      });
    } else if (name_attribute === "Solar Trackers Plants") {
      return new MVTLayer({
        id: `mvtSolarTrackersPlants${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_solar_panels_plants_trackers/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209, 0],
        getLineWidth: 2,
        getLineColor: [112, 39, 209],
        lineWidthUnits: "pixels",
      });
    } else if (name_attribute === "Wind Turbines") {
      return new MVTLayer({
        id: `mvtWindTurbines${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_wind_turbines/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getPointRadius: 5,
        pointRadiusUnits: "meters",
        pointRadiusScale: 3,
        pointType: "circle",
        getFillColor: [112, 39, 209],
        getLineColor: [112, 39, 209],
      });
    } else if (name_attribute === "Humidity") {
      return new MVTLayer({
        id: `mvtHumidity${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_humidity/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: subimage !== "DTM" ? 0.7 : 2,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.ndwi !== null) {
            return COLOR_SCALE_HUMIDITY(f.properties.ndwi);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.ndwi !== null) {
            return [0, 0, 0, 1];
          }
        },
      });
    } else if (name_attribute === "Water bodies") {
      return new MVTLayer({
        id: `mvtWaterBodies${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_water_bodies/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        //onViewportLoad,
        //radiusPixels: 0.1,
        getPointRadius: subimage !== "DTM" ? 0.7 : 2,
        pickable: true,
        //pointRadiusUnits: zoom > 19 ? "meters" : "pixels",
        getFillColor: (f) => {
          if (f.properties.ndwi !== null) {
            return COLOR_SCALE_WATER_BODIES(f.properties.ndwi);
          }
        },
        getLineWidth: 0.5,
        getLineColor: (f) => {
          if (f.properties.ndwi !== null) {
            return [0, 0, 0, 1];
          }
        },
      });
    } else if (name_attribute === "Segmentation") {
      return new MVTLayer({
        id: `mvtSegmentation${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_segmentation/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209, 0],
        getLineWidth: 2,
        getLineColor: [112, 39, 209],
        lineWidthUnits: "pixels",
      });
    } else if (name_attribute === "Burned areas") {
      return new MVTLayer({
        id: `mvtBurnedareas${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_burned_areas/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getFillColor: [112, 39, 209],
        getLineWidth: 2,
        getLineColor: [112, 39, 209],
        lineWidthMinPixels: 2,
      });
    } else if (name_attribute === "Monitoring") {
      return new MVTLayer({
        id: `mvtMonitoring_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_monitoring/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        getPointRadius: subimage !== "DTM" ? 0.7 : 2,
        pickable: true,
        getLineWidth: 2,
        getFillColor: [0, 0, 0, 0],
        getLineColor: [112, 39, 209],
      });
    } else if (name_attribute === "Night time lights") {
      return new MVTLayer({
        id: `mvtNightTimeLights_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_night_time_lights/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        getPointRadius: subimage !== "DTM" ? 0.7 : 2,
        pickable: true,
        getFillColor: (f) => {
          if (f.properties.luminosity !== null) {
            return COLOR_SCALE_NIGHT_TIME_LIGHTS(f.properties.luminosity);
          }
        },
      });
    } else if (name_attribute === "Contour Lines") {
      return new MVTLayer({
        id: `mvtContourLines_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_contour_lines/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        getPointRadius: subimage !== "DTM" ? 0.7 : 2,
        pickable: true,
        getLineWidth: ({ properties }) => {
          const zoom = properties.z;
          return subimage !== "DTM" ? 0.3 : 19 - 0.9 * zoom;
        },
        getFillColor: [0, 0, 0, 0],
        getLineColor: [112, 39, 209],
      });
    } else if (name_attribute === "Vehicles") {
      return new MVTLayer({
        id: `mvtVehicles_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_vehicles/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        getPointRadius: subimage !== "DTM" ? 0.7 : 2,
        pickable: true,
        getLineWidth: 0.3,
        getFillColor: [0, 0, 0, 0],
        getLineColor: [112, 39, 209],
      });
    } else if (name_attribute === "Roads") {
      return new MVTLayer({
        id: `mvtRoads_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_roads/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        getPointRadius: subimage !== "DTM" ? 0.7 : 2,
        pickable: true,
        getLineWidth: 0.3,
        getFillColor: [112, 39, 209],
        getLineColor: [112, 39, 209],
      });
    } else if (name_attribute === "Earthworks Monitoring") {
      return new MVTLayer({
        id: `mvtEarthworksMonitoring_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_earthworks_monitoring/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        getPointRadius: subimage !== "DTM" ? 0.7 : 2,
        pickable: true,
        getLineColor: [0, 0, 0, 0],
        getFillColor: (f) => {
          if (f.properties.category_id !== null) {
            return COLOR_SCALE_EARTHWORK_MONITORING(f.properties.category_id);
          }
        },
      });
    } else if (name_attribute === "High Tension Towers") {
      return new MVTLayer({
        id: `mvtHighTensionTowers_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_high_tension_towers/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getPointRadius: 5,
        pointRadiusUnits: "meters",
        pointRadiusScale: 3,
        pointType: "circle",
        getFillColor: [112, 39, 209],
        getLineColor: [112, 39, 209],
      });
    } else if (name_attribute === "Medium Tension Towers") {
      return new MVTLayer({
        id: `mvtMediumTensionTowers_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_medium_tension_towers/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        pickable: true,
        getPointRadius: 5,
        pointRadiusUnits: "meters",
        pointRadiusScale: 3,
        pointType: "circle",
        getFillColor: [112, 39, 209],
        getLineColor: [112, 39, 209],
      });
    } else if (name_attribute === "Trenches") {
      return new MVTLayer({
        id: `mvtTrenches_${id_dataset}_${id_project}`,
        opacity: transparency / 100,
        data: `${SERVER}/rpc/public.get_trenches/{z}/{x}/{y}.pbf?id_dataset_p=${id_dataset}&id_project_p=${id_project}`,
        getPointRadius: subimage !== "DTM" ? 0.7 : 2,
        pickable: true,
        getLineWidth: 0.3,
        getFillColor: [112, 39, 209],
        getLineColor: [112, 39, 209],
      });
    }
  };

  const fetchMVTLayer = async (name_attribute, id_project, id_dataset, transparency, subimage) => {
    const project = listProject.find((p) => p.id_project === id_project);
    const area = project.area;
    setArea(area);
    if (
      name_attribute === "Radiation" ||
      name_attribute === "Altitude" ||
      name_attribute === "Vegetation height" ||
      name_attribute === "Height"
    ) {
      const optionalParameters = {
        kpisGroups: name_attribute === "Radiation" ? baseRadiationGroups : baseAltitudeGroups,
      };

      await fetchKPISData(id_project, id_dataset)
        .then(function (response) {
          if (response.status === 200) {
            optionalParameters.kpisGroups = response.data.groups;
            setDataResultsMap((previous) => {
              return {
                ...previous,
                [id_dataset]: response.data,
              };
            });
          } else {
            setDataResultsMap((previous) => {
              return {
                ...previous,
                [id_dataset]: {
                  ...previous[id_dataset],
                  chart: {},
                  groups: optionalParameters.kpisGroups,
                },
              };
            });
          }
        })
        .catch(function (err) {
          setSnackbarProps({
            message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
            severity: "error",
          });
          setOpenSnackbar(true);
        });

      return getMVTLayer(name_attribute, id_project, id_dataset, transparency, optionalParameters, subimage);
    } else {
      return getMVTLayer(name_attribute, id_project, id_dataset, transparency, undefined, subimage);
    }
  };

  const getXyzLayer = async (id_project, id_dataset) => {
    const project = listProject.find((p) => p.id_project === id_project);
    let layer = undefined;
    const area = project.area;
    setArea(area);

    const token = localStorage.getItem("token_access_imageryst");

    await getXyzFolder(id_project, id_dataset)
      .then(function (response) {
        let dir = response.data.tiles_dir;
        dir = dir.substring(dir.lastIndexOf("/") + 1);
        layer = new TileLayer({
          id: `xyz${id_dataset}_${id_project}`,
          data: `${APPSERVER}tile_service/retrieve_tiles/${dir}/{z}/{x}/{y}.png`,
          loadOptions: {
            fetch: {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          },
          maxZoom: 17,
          minZoom: 11,
          pickable: true,
          opacity: 100,
          renderSubLayers: (props) => {
            const { boundingBox } = props.tile;
            return new BitmapLayer(props, {
              data: null,
              image: props.data,
              bounds: [boundingBox[0][0], boundingBox[0][1], boundingBox[1][0], boundingBox[1][1]],
            });
          },
        });
      })
      .catch((error) => {
        setSnackbarProps({ message: error.response.data.message, severity: "error" });
        setOpenSnackbar(true);
      });
    return layer;
  };

  const handleChangeCheckLayer = async (event, id_project, id_dataset, name_attribute, transparency, subimage) => {
    event.stopPropagation();
    const dataSetSelect = listDataset[id_project].find((p) => p.id_dataset === id_dataset);
    dataSetSelect.checked = event.target.checked;
    setListDataset(listDataset);

    const xyzLayerId = `xyz${id_dataset}_${id_project}`;

    setOptionSelectKPI(`${id_dataset}:${id_project}`);
    if (event.target.checked) {
      const layer = await fetchMVTLayer(name_attribute, id_project, id_dataset, transparency, subimage);
      const xyzLayer = await getXyzLayer(id_project, id_dataset);

      if (layer !== undefined) {
        if (xyzLayer) {
          setLayersArray((oldArray) => [xyzLayer, ...oldArray, layer]);
        } else {
          setLayersArray((oldArray) => [...oldArray, layer]);
        }
      }
    } else {
      const layerId = getMVTName(name_attribute, id_dataset, id_project);

      if (layerId !== "") {
        const layerNoSelect = layersArray.filter((layer) => layer.id !== layerId && layer.id !== xyzLayerId);

        setLayersArray(layerNoSelect);
      }
    }
    if (listDataset[id_project])
      listDataset[id_project].forEach((dataset) => {
        if (dataset.id_dataset === id_dataset) {
          dataset.checked = event.target.checked;
        }
      });
  };

  const getProjectList = async () => {
    const projectName = searched ? searched : null;
    await getNumberPages(projectName, "Completed")
      .then(function (response) {
        if (response.status === 200) {
          setNumberPages(response.data.number_of_pages);
        }
      })
      .catch(function (err) {
        setOpenLoader(false);
        setSnackbarProps({
          message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
          severity: "error",
        });
        setOpenSnackbar(true);
      });
    await getProjectByUser(projectName ? 1 : page, projectName, null, null, "Completed")
      .then(function (response) {
        if (response.status === 200) {
          const listProjectCheck = Object.values(response.data.message).map((p: any) => ({
            ...p,
            checked: false,
            content: "",
            date: "",
            display: true,
            transparency: 75,
          }));

          //ordenar por last_executed
          listProjectCheck.sort((x, y) => {
            const xDate = new Date(
              x.creation_date.split("-")[1] + "-" + x.creation_date.split("-")[0] + "-" + x.creation_date.split("-")[2],
            );
            const yDate = new Date(
              y.creation_date.split("-")[1] + "-" + y.creation_date.split("-")[0] + "-" + y.creation_date.split("-")[2],
            );
            return yDate.getTime() - xDate.getTime();
          });
          setListProject(listProjectCheck);
        }
      })
      .catch(function (err) {
        setListProject([]);
        if (err.response.status !== 404) {
          setSnackbarProps({
            message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
            severity: "error",
          });
          setOpenSnackbar(true);
        }
      });

    localStorage.removeItem("idProject");
    localStorage.removeItem("nameProject");
  };

  useEffect(() => {
    getProjectList();
  }, []);

  const cleanLayers = (id_project) => {
    //remove all layer visible
    const arrayListLayerRemove = [];
    listDataset[id_project]?.forEach((d) => {
      const layerId = getMVTName(d.name_attribute, d.id_dataset, d.id_project);

      arrayListLayerRemove.push(layerId);
    });
    const layerNoSelect = layersArray.filter((layer) => {
      arrayListLayerRemove.forEach((l) => {
        return layer.id !== l;
      });
    });

    setLayersArray(layerNoSelect);
    delete listDataset[id_project];
    setDataResultsMap({});
  };

  const handleChangeProject = async (event, id_project, index, name_project) => {
    const project = listProject.find((p) => p.id_project === id_project);
    const area = project.area;

    setAoi(project.aoi);

    setArea(area);

    cleanLayers(id_project);

    const listProjectArray = [...listProject];
    listProjectArray.map((project, key) => {
      if (key === index) {
        project.checked = event.target.checked;
      } else {
        project.checked = false;
        setDataResultsMap({});
      }
    });
    setListProject(listProjectArray);

    const id_user = localStorage.getItem("id_user");
    if (event.target.checked) {
      await fetchData(`${id_user}/${id_project}`, "GET_DATASET")
        .then(function (response) {
          if (response.status === 200) {
            const listDatasetCheck = Object.values(response.data.message).map((p: any) => ({
              ...p,
              checked: false,
              display: true,
              project_name: name_project,
              transparency: 75,
            }));

            if (response.data.message.length > 0) {
              let features = [];
              response.data.message.forEach((item) => {
                features = [...features, ...item.input_alg_parameters.mask.features];
              });
              try {
                const bbox = turfBbox({
                  type: "FeatureCollection",
                  features: features,
                });
                setInitialMapBbox({ bbox: bbox, zoom: listDatasetCheck[0]?.default_zoom });
              } catch (error) {
                setSnackbarProps({ message: error.response.data.message, severity: "error" });
                setOpenSnackbar(true);
              }
            }

            setListDataset(() => {
              return {
                [id_project]: listDatasetCheck,
              };
            });
            dispatch(actions.setOpenMapPanel(true));
          }
        })
        .catch(function (err) {
          setSnackbarProps({
            message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
            severity: "error",
          });
          setOpenSnackbar(true);
        });
    } else {
      cleanLayers(id_project);
    }
  };

  useEffect(() => {
    // Scroll to the top of the component when content changes
    if (lateralMenuRef?.current) lateralMenuRef.current.scrollTo(0, 0);
  }, [page]);

  const handleChangePagination = async (event, value) => {
    setOpenLoader(true);
    await getProjectByUser(value, null, null, null, "Completed")
      .then(function (response) {
        setOpenLoader(false);
        if (response.status === 200) {
          const listProjectCheck = Object.values(response.data.message).map((p: any) => ({
            ...p,
            checked: false,
            content: "",
            date: "",
            display: true,
            transparency: 75,
          }));

          //ordenar por last_executed
          listProjectCheck.sort((x, y) => {
            const xDate = new Date(
              x.creation_date.split("-")[1] + "-" + x.creation_date.split("-")[0] + "-" + x.creation_date.split("-")[2],
            );
            const yDate = new Date(
              y.creation_date.split("-")[1] + "-" + y.creation_date.split("-")[0] + "-" + y.creation_date.split("-")[2],
            );
            return yDate.getTime() - xDate.getTime();
          });
          setListProject(listProjectCheck);
        }
      })
      .catch(function (err) {
        setSnackbarProps({
          message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
          severity: "error",
        });
        setOpenSnackbar(true);
      });
    setPage(value);
  };

  const handleChangeSearchDataset = () => {
    const projectsToClean = listProject.filter((project) => {
      return project.checked;
    });

    for (const project of projectsToClean) {
      cleanLayers(project.id_project);
    }

    history.push({
      pathname: navigation.dashboardResults,
      search: searched ? `?projectName=${searched}` : "",
    });
    getProjectList();
    if (lateralMenuRef?.current) lateralMenuRef.current.scrollTo(0, 0);
  };

  const queryParameters = new URLSearchParams(window.location.search);
  const projectNameFilter = queryParameters.get("projectName");
  const [searched, setSearched] = useState(projectNameFilter ? projectNameFilter : "");
  const requestSearch = (event) => {
    const searchedVal = event.target.value;
    setSearched(searchedVal);
  };

  const updateDataset = (idProject, updatedDataset) => {
    const newListDataset = listDataset[idProject].map((currentDataset) => {
      if (currentDataset.id_dataset == updatedDataset.id_dataset) {
        return { ...currentDataset, ...updatedDataset };
      }
      return currentDataset;
    });
    setListDataset({ ...listDataset, [idProject]: newListDataset });
  };

  return (
    <div className="container">
      <div className="container_map">
        <LateralMenu
          listProject={listProject}
          handleChangeProject={handleChangeProject}
          numberPages={numberPages}
          page={page}
          handleChangePagination={handleChangePagination}
          openLoader={openLoader}
          setOpenLoader={setOpenLoader}
          handleSearch={handleChangeSearchDataset}
          searchValue={searched}
          onChangeSearchValue={requestSearch}
          lateralMenuRef={lateralMenuRef}
        />
        <Map layersArray={layersArray} initialMapBbox={initialMapBbox} setLayersArray={setLayersArray} aoi={aoi} />
        <MenuMap
          listDataset={listDataset}
          updateDataset={updateDataset}
          handleChangeCheckLayer={handleChangeCheckLayer}
          dataResultsMap={dataResultsMap}
          handleChangeSelect={handleChangeSelect}
          optionSelectKPI={optionSelectKPI}
          handleChangeTransparency={handleChangeTransparency}
          handleChangeTransparencyXyz={handleChangeTransparencyXyz}
          area={area}
          setSnackbarProps={setSnackbarProps}
          setOpenSnackbar={setOpenSnackbar}
        />
      </div>
      <CustomSnackbar snackbarProps={snackbarProps} openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} />
    </div>
  );
}

export default Dashboard;
