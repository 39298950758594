import { Card, CardContent, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyles from "../../../theme/styles";
import { PieChart } from "../../chart";

function DatasetKpisInfo(props: any) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { listDataset, area, optionSelectKPI, handleChangeSelect, selectedDataset, kpisInfo } = props;

  return (
    <>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 248 }} className={`select_white ${classes.select}`}>
        <InputLabel className="label_select">{t("dashboardResults.selectDataset")}</InputLabel>
        <Select className="kpi-select" value={optionSelectKPI} name="useCase" onChange={handleChangeSelect}>
          {Object.values(listDataset).map((pro: any) =>
            pro.map(({ id_dataset, id_project, dataset_name }: any) => {
              return <MenuItem value={`${id_dataset}:${id_project}`}>{dataset_name}</MenuItem>;
            }),
          )}
        </Select>
      </FormControl>
      <div className={classes.divCardContent}>
        <Card sx={{ minWidth: "30%" }} className={`${classes.card} ${classes.card50}`}>
          <CardContent className={classes.card_contentkpi}>
            <Typography sx={{ fontSize: 16 }} gutterBottom className="item-label">
              <InputLabel className="result-label">{t("dashboardResults.resultsItems")}</InputLabel>
            </Typography>
            <div className={classes.card_kpi_items}>
              <Typography variant="h5" gutterBottom component="div">
                {kpisInfo?.count && optionSelectKPI ? kpisInfo.count : "--"}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </div>
      <Card sx={{ minWidth: "30%" }} className={classes.card}>
        <CardContent className={classes.card_contentkpi}>
          <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
            <InputLabel className="result-label">{t("dashboardResults.resultsAverage")}</InputLabel>
          </Typography>
          <div className={classes.card_kpi}>
            <Typography variant="h5" gutterBottom component="div">
              {optionSelectKPI && kpisInfo?.average ? kpisInfo.average.toFixed(2) : "--"}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: "30%" }} className={classes.card}>
        <CardContent className={classes.card_contentkpi}>
          <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
            <InputLabel className="result-label">{t("dashboardResults.max")}</InputLabel>
          </Typography>
          <div className={classes.card_kpi}>
            <Typography variant="h5" gutterBottom component="div">
              {optionSelectKPI && kpisInfo?.maximum ? kpisInfo.maximum : "--"}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: "30%" }} className={classes.card}>
        <CardContent className={classes.card_contentkpi}>
          <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
            <InputLabel className="result-label">{t("dashboardResults.min")}</InputLabel>
          </Typography>
          <div className={classes.card_kpi}>
            <Typography variant="h5" gutterBottom component="div">
              {optionSelectKPI && kpisInfo?.minimum ? kpisInfo.minimum : "--"}
            </Typography>
          </div>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: "30%" }} className={classes.card}>
        <CardContent className={classes.card_contentkpi}>
          <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
            <InputLabel className="result-label">{t("dashboardResults.area")}</InputLabel>
          </Typography>
          <div className={classes.card_kpi}>
            <Typography variant="h5" gutterBottom component="div">
              {area}
            </Typography>
          </div>
        </CardContent>
      </Card>
      {kpisInfo?.chart ? (
        <Card sx={{ minWidth: "30%" }} className={classes.card}>
          <CardContent className={classes.card_contentkpi}>
            <PieChart
              type={selectedDataset.name_attribute}
              title={selectedDataset.dataset_name}
              kpisChartInfo={kpisInfo.chart}
              units={kpisInfo.units}
            />
          </CardContent>
        </Card>
      ) : null}
    </>
  );
}

export default DatasetKpisInfo;
