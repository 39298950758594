import { Grid, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyles from "../../theme/styles";
import "./style.scss";

const COLOR_SCALE_RADIATION = [
  "rgb(59, 27, 0)",
  "rgb(93, 42, 0)",
  "rgb(171, 86, 3)",
  "rgb(198, 105, 5)",
  "rgb(237, 165, 33)",
  "rgb(250, 198, 53)",
  "rgb(255, 230, 70)",
];

const COLOR_SCALE_ASPECT = [
  "rgb(141, 90, 153)",
  "rgb(250, 232, 35)",
  "rgb(251, 22, 12)",
  "rgb(60, 234, 17)",
  "rgb(141, 90, 153)",
];

const COLOR_SCALE_SLOPE = [
  "rgb(26, 150, 65)",
  "rgb(166, 217, 106)",
  "rgb(230, 255, 192)",
  "rgb(253, 174, 97)",
  "rgb(215, 25, 28)",
];

const COLOR_SCALE_NDVI = [
  "rgb(251, 22, 12)",
  "rgb(239, 69, 17)",
  "rgb(239, 171, 25)",
  "rgb(26, 150, 65)",
  "rgb(21, 225, 72)",
  "rgb(124, 237, 96)",
];

const COLOR_SCALE_ALTITUDE = [
  "rgb(0, 102, 51)",
  "rgb(0, 153, 51)",
  "rgb(0, 204, 102)",
  "rgb(153, 255, 102)",
  "rgb(255, 255, 102)",
  "rgb(255, 204, 102)",
  "rgb(255, 153, 51)",
  "rgb(204, 102, 0)",
  "rgb(153, 102, 51)",
  "rgb(102, 51, 0)",
];

const COLOR_SCALE_VEGETATION_HEALTH = [
  "rgb(215, 25, 28)",
  "rgb(240, 124, 74)",
  "rgb(254, 201, 129)",
  "rgb(196, 230, 135)",
  "rgb(119, 195, 92)",
  "rgb(91, 174, 61)",
  "rgb(26, 150, 65)",
];

const COLOR_SCALE_HUMIDITY = [
  "rgb(35,145,36)",
  "rgb(154,204,151)",
  "rgb(210, 252, 205)",
  "rgb(213, 242, 255)",
  "rgb(32, 144, 251)",
  "rgb(11, 51, 236)",
  "rgb(5, 25, 135)",
];

const COLOR_SCALE_WATER_BODIES = ["rgb(42, 185, 238)", "rgb(66, 73, 177)", "rgb(48, 18, 59)"];

const COLOR_SCALE_NIGHT_TIME_LIGHTS = [
  "rgb(0, 0, 0)",
  "rgb(36, 31, 0)",
  "rgb(73, 62, 0)",
  "rgb(109, 93, 0)",
  "rgb(146, 124, 0)",
  "rgb(182, 155, 0)",
  "rgb(219, 186, 0)",
  "rgb(255, 213, 0)",
];

const COLOR_SCALE_EARTHWORK_MONITORING = [
  "rgb(178, 223, 138)", 
  "rgb(27, 160, 0)", 
  "rgb(150, 150, 150)",
  "rgb(39, 191, 229)",
  "rgb(255, 235, 133)",
];

const COLOR_SCALES = {
  Altitude: COLOR_SCALE_ALTITUDE,
  Radiation: COLOR_SCALE_RADIATION,
  Aspect: COLOR_SCALE_ASPECT,
  Slope: COLOR_SCALE_SLOPE,
  "Slope (Pitch)": COLOR_SCALE_SLOPE,
  NDVI: COLOR_SCALE_NDVI,
  "Vegetation health": COLOR_SCALE_VEGETATION_HEALTH,
  Humidity: COLOR_SCALE_HUMIDITY,
  "Water bodies": COLOR_SCALE_WATER_BODIES,
  "Night time lights": COLOR_SCALE_NIGHT_TIME_LIGHTS,
  Height: COLOR_SCALE_ALTITUDE,
  "Vegetation height": COLOR_SCALE_ALTITUDE,
  "Earthworks Monitoring": COLOR_SCALE_EARTHWORK_MONITORING,
};

const StyleCard = ({ type, kpisGroups, legendData, checked }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const legendCaptions = {
    Altitude: t("common.metersAvobeSeaLevel"),
    Height: t("common.metersAvobeSeaLevel"),
    "Vegetation height": t("common.metersAvobeSeaLevel"),
    Radiation: t("common.radiationUnits"),
    Aspect: t("common.degrees", { degreeRange: "(0-360º)" }),
    Slope: t("common.degrees", { degreeRange: "(0-90º)" }),
    "Slope (Pitch)": t("common.degrees", { degreeRange: "(0-90º)" }),
    "Vegetation health": t("common.status"),
    Humidity: t("common.status"),
    "Water bodies": t("common.status"),
    "Night time lights": t("common.status"),
    "Earthworks Monitoring": t("common.status"),
  };

  const formatValue = (value, decimals = 0) => {
    if (typeof value === "number") {
      const v = formatNumber(value, decimals).toString();
      const formated = v.replace(".", ",");
      return formated;
    }
    return value;
  };

  const formatNumber = (value, decimals = 2) => {
    if (value === 0) {
      return value;
    } else if (value === 1) {
      return value;
    } else if (value === -1) {
      return value;
    } else {
      return value.toFixed(decimals);
    }
  };

  const renderLegend = (colorValues, values) => {
    return (
      <div className="layout" style={{ display: "table", width: "200px", height: "10px" }}>
        {colorValues.map((element, index) => {
          return (
            <Tooltip title={`${values && values.length > 0 ? values[index] : "--"}`} placement="top-end">
              <div className={classes.legend} style={{ backgroundColor: element }}></div>
            </Tooltip>
          );
        })}
      </div>
    );
  };

  const renderLegendInfo = (checked, values) => {
    return checked ? (
      <Grid container sx={{ m: 0, alignItems: "center" }}>
        <Grid item xs={6}>
          <p style={{ marginTop: 2, marginBottom: 2, fontSize: "12px" }}>
            {values && values.length > 0 ? formatValue(values[0]) : "--"}
          </p>
        </Grid>
        <Grid container item xs={6} sx={{ justifyContent: "end" }}>
          <p style={{ marginTop: 2, marginBottom: 2, fontSize: "12px" }}>
            {values && values.length > 0 ? formatValue(values[values.length - 1]) : "--"}
          </p>
        </Grid>
      </Grid>
    ) : null;
  };

  return (
    <>
      {type === "NDVI" && (
        <div>
          {renderLegend(COLOR_SCALES[type], legendData)}
          {renderLegendInfo(checked, kpisGroups)}
        </div>
      )}
      {Object.keys(legendCaptions).includes(type) && (
        <div>
          <p style={{ fontSize: "12px", margin: "0px" }}>{legendCaptions[type]}</p>
          {renderLegend(COLOR_SCALES[type], legendData)}
          {renderLegendInfo(checked, kpisGroups)}
        </div>
      )}
    </>
  );
};

export default StyleCard;
