import React, { useState } from "react";
import ButtonCustom from "../../components/ui/button";
import useStyles from "../../theme/styles";
import {
  Typography,
  Card,
  CardContent,
  InputAdornment,
  FilledInput,
  CardMedia,
  CardActions,
  IconButton,
  CardHeader,
  Menu,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CachedIcon from "@mui/icons-material/Cached";

import lansat_img from "../../img/Landsat.jpg";
import sentinel_img from "../../img/Sentinel.jpg";
import panet_img from "../../img/Planetscope.jpg";
import lidar_img from "../../img/LIDAR.jpg";
import pleiades_img from "../../img/Pleiades.jpg";
import spot_img from "../../img/Spot.jpg";
import world_img from "../../img/Worldview.jpg";

import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import "./style.scss";

const dataImageCatalogFavourite = {
  0: {
    title: "Lidar",
    content:
      "Very high resolution point clouds, containing measurements of several points per square metre and allowing3D measurements.",
    date: "04/18/2022",
    times: "20 times",
    imgUrl: lidar_img,
  },
  1: {
    title: "Sentinel (Copernicus)",
    content:
      "A constellation of two identical satellites in the same orbit, Copernicus Sentinel-2 images land and coastal areas at high spatial resolution in the optical domain.",
    date: "04/15/2022",
    times: "18 times",
    imgUrl: sentinel_img,
  },
  2: {
    title: "Landsat (USGS)",
    content:
      "Landsat 9 will continue the Landsat program’s critical role of repeat global observations for monitoring, understanding, and managing Earth’s natural resources.",
    date: "04/03/2022",
    times: "23 times",
    imgUrl: lansat_img,
  },
};

const dataImageCatalog = {
  0: {
    title: "Pleiades (Airbus)",
    content:
      "Providing 30 cm resolution combined with the most accurate geolocation, Pléiades Neo brings a significant level of information required for precise large-scale mapping in a military or civilian.",
    date: "03/03/2022",
    times: "15 times",
    imgUrl: pleiades_img,
  },
  1: {
    title: "Sentinel (Copernicus)",
    content:
      "A constellation of two identical satellites in the same orbit, Copernicus Sentinel-2 images land and coastal areas at high spatial resolution in the optical domain.",
    date: "04/15/2022",
    times: "18 times",
    imgUrl: sentinel_img,
  },
  2: {
    title: "Spot (Airbus)",
    content:
      "SPOT 6 and SPOT 7 satellites imagery offer an affordable source of information for analysing, monitoring, forecasting and managing resources and human activity on our planet.",
    date: "04/01/2022",
    times: "20 times",
    imgUrl: spot_img,
  },
  3: {
    title: "Landsat (USGS)",
    content:
      "Landsat 9 will continue the Landsat program’s critical role of repeat global observations for monitoring, understanding, and managing Earth’s natural resources.",
    date: "04/03/2022",
    times: "23 times",
    imgUrl: lansat_img,
  },
  4: {
    title: "Worldview (Maxar)",
    content:
      "Provides high resolution optical imagery. The WorldView constellations consists of four active satellites: WorldView-1, GeoEye-1, WorldView-2 and WorldView-3.",
    date: "03/22/2022",
    times: "14 times",
    imgUrl: world_img,
  },
  5: {
    title: "PlanetScope (Planet)",
    content: "Providing 3m resolution brings a significant level of information in 4 band (RGB, NIR).",
    date: "04/05/2022",
    times: "20 times",
    imgUrl: panet_img,
  },
  6: {
    title: "Lidar",
    content:
      "Very high resolution point clouds, containing measurements of several points per square metre and allowing3D measurements.",
    date: "04/18/2022",
    times: "20 times",
    imgUrl: lidar_img,
  },
};

function Catalog() {
  const classes = useStyles();
  localStorage.setItem("openPanelLeft", "");
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <div className="container">
      <div className={classes.headerTitle}>
        <div>
          <FilledInput
            value="Search image"
            id="outlined-start-adornment"
            endAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            size="small"
            className={classes.inputWhite}
          />
          <ButtonCustom label="New Image" className="buttonStyleColor" variant="contained" />
        </div>
      </div>
      <Typography sx={{ fontSize: 20, width: "33%", flexShrink: 0 }} className={classes.title}>
        Favourites
      </Typography>
      <div className={classes.headerContent}>
        {Object.values(dataImageCatalogFavourite).map(({ title, content, date, times, imgUrl }, index) => {
          return (
            <Card sx={{ maxWidth: 345 }} key={index}>
              <StarIcon className={classes.iconFavo} />
              <CardMedia component="img" height="200" image={imgUrl} alt="img " />
              <CardHeader
                action={
                  <IconButton
                    aria-label="more"
                    onClick={handleClick}
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
                title={title}
              />
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                disableScrollLock={true}
              >
                <MenuItem onClick={handleClose}>
                  <StarBorderIcon className={classes.marginRight} />
                  <Typography variant="inherit">Favorite</Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <DeleteOutlineIcon className={classes.marginRight} />
                  <Typography variant="inherit">Map view</Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <RemoveRedEyeOutlinedIcon className={classes.marginRight} />
                  <Typography variant="inherit">Delete</Typography>
                </MenuItem>
              </Menu>
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {content}
                </Typography>
              </CardContent>
              <CardActions>
                <CalendarTodayIcon fontSize="small" className={classes.marginRight} />
                <Typography variant="body2" color="text.secondary">
                  {date}
                </Typography>
                <CachedIcon fontSize="small" />
                <Typography variant="body2" color="text.secondary">
                  {times}
                </Typography>
              </CardActions>
            </Card>
          );
        })}
      </div>
      <Typography sx={{ fontSize: 20, width: "33%", flexShrink: 0 }} className={classes.title}>
        Images
      </Typography>
      <div className={classes.headerContent}>
        {Object.values(dataImageCatalog).map(({ title, content, date, times, imgUrl }, index) => {
          return (
            <Card sx={{ maxWidth: 345 }} key={index}>
              <StarBorderIcon className={classes.iconNoFavo} />
              <CardMedia component="img" height="200" image={imgUrl} alt="img " />
              <CardHeader
                action={
                  <IconButton
                    aria-label="more"
                    onClick={handleClick}
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
                title={title}
              />
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                disableScrollLock={true}
              >
                <MenuItem onClick={handleClose}>
                  <StarBorderIcon className={classes.marginRight} />
                  <Typography variant="inherit">Favorite</Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <DeleteOutlineIcon className={classes.marginRight} />
                  <Typography variant="inherit">Map view</Typography>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <RemoveRedEyeOutlinedIcon className={classes.marginRight} />
                  <Typography variant="inherit">Delete</Typography>
                </MenuItem>
              </Menu>
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {content}
                </Typography>
              </CardContent>
              <CardActions>
                <CalendarTodayIcon fontSize="small" className={classes.marginRight} />
                <Typography variant="body2" color="text.secondary">
                  {date}
                </Typography>
                <CachedIcon fontSize="small" />
                <Typography variant="body2" color="text.secondary">
                  {times}
                </Typography>
              </CardActions>
            </Card>
          );
        })}
      </div>
      <div className={classes.pageTitle}></div>
      <div className={classes.pageContent}></div>
    </div>
  );
}

export default Catalog;
