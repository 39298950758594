import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "../utils/@reduxjs/toolkit";
import { useInjectReducer } from "redux-injectors";
import { User } from "../models/login";
import { AppState } from "./types";

export const initialState: AppState = {
  user: undefined,
  openPanelLeft: false,
  openMapPanel: false,
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setUser(state, action?: PayloadAction<User>) {
      state.user = action?.payload;
    },
    setOpenPanelLeft(state, action: PayloadAction<boolean>) {
      state.openPanelLeft = action.payload;
    },
    setOpenMapPanel(state, action: PayloadAction<boolean>) {
      state.openMapPanel = action.payload;
    },
  },
});

export const { actions: appActions } = slice;

export const useAppSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
