import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { anOldHope } from "react-syntax-highlighter/dist/esm/styles/hljs";

function Sandbox(props) {
  const codeString = "";

  return (
    <SyntaxHighlighter language="python" style={anOldHope} showLineNumbers wrapLongLines>
      {codeString}
    </SyntaxHighlighter>
  );
}

export default Sandbox;
