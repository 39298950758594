// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu_container {
  height: 72px;
  background-color: #ffffff;
  box-shadow: 12px 8px 44px -12px #e6e6e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0px;
  border-bottom: 1px solid #d1d1d1;
  z-index: 10;
}

.logo_imageryst {
  width: 55.13px;
  height: 51.75px;
}

.user {
  padding-right: 60px;
  display: flex;
}
.user img {
  width: 40px;
  height: 40px;
}

.menu {
  border-color: rgba(0, 0, 0, 0) !important;
}

.tabs {
  color: grey !important;
}
.tabs button {
  padding: 20px !important;
}
.tabs .Mui-selected {
  color: #7024d1 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/header/style.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,yBAAA;EACA,uCAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;EACA,QAAA;EACA,gCAAA;EACA,WAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ;;AAGA;EACE,yCAAA;AAAF;;AAGA;EACE,sBAAA;AAAF;AAEE;EACE,wBAAA;AAAJ;AAGE;EACE,yBAAA;AADJ","sourcesContent":[".menu_container {\n  height: 72px;\n  background-color: #ffffff;\n  box-shadow: 12px 8px 44px -12px #e6e6e8;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  position: fixed;\n  width: 100%;\n  top: 0px;\n  border-bottom: 1px solid #d1d1d1;\n  z-index: 10;\n}\n\n.logo_imageryst {\n  width: 55.13px;\n  height: 51.75px;\n}\n\n.user {\n  padding-right: 60px;\n  display: flex;\n\n  img {\n    width: 40px;\n    height: 40px;\n  }\n}\n\n.menu {\n  border-color: rgba(0, 0, 0, 0) !important;\n}\n\n.tabs {\n  color: grey !important;\n\n  button {\n    padding: 20px !important;\n  }\n\n  & .Mui-selected {\n    color: #7024d1 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
