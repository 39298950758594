import { configureStore, StoreEnhancer } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { createReducer } from "./reducers";
import { createInjectorsEnhancer } from "redux-injectors";

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  const middlewares = [sagaMiddleware];

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ] as StoreEnhancer[];

  const store = configureStore({
    reducer: createReducer(),
    middleware: (defaultMiddleware) => [...defaultMiddleware(), ...middlewares],
    enhancers,
  });

  return store;
}
