import { Grid, IconButton, TextField, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

function EditTextField(props: any) {
  const { editMode, originalName, newText, onChangeText, onEditClick, onSave, onCancel } = props;

  return (
    <>
      <Grid item xs={6}>
        {!editMode ? (
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {originalName}
          </Typography>
        ) : (
          <TextField
            variant="outlined"
            maxRows={4}
            onClick={(event) => {
              event.stopPropagation();
            }}
            sx={{
              maxWidth: "200px",
              "& .MuiInputBase-input": {
                padding: "5px",
                fontSize: "14px", // Tamaño de letra del input
              },
              pointerEvents: "auto",
            }}
            value={newText}
            onChange={onChangeText}
          />
        )}
      </Grid>
      <Grid
        item
        container
        xs={!editMode ? 2 : 3}
        sx={{
          justifyContent: "left",
        }}
      >
        {!editMode ? (
          <Grid item>
            <IconButton sx={{ pointerEvents: "auto" }} onClick={onEditClick}>
              <EditIcon sx={{ color: "#000" }} />
            </IconButton>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={6}>
              <IconButton sx={{ pointerEvents: "auto" }} onClick={onSave}>
                <SaveIcon color="success" />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <IconButton sx={{ pointerEvents: "auto" }} onClick={onCancel}>
                <CancelIcon color="error" />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default EditTextField;
