/* eslint-disable no-undef */
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, styled, TextField, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import "./style.scss";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ButtonCustom from "../../../components/ui/button";

import { uploadUserImage } from "../../../utils/requests";
import { useEffect } from "react";
import dayjs from "dayjs";

const IMAGE_TYPES = {
  lidar: "LIDAR",
  satellite: "Satellite",
  basemap: "Basemap",
};

function ImageBuilder(props: any) {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const { t } = useTranslation();

  const { imageData, setImageData, setIsAddView, setOpenSnackbar, setSnackbarProps, setOpenLoader } = props;

  //Created to cleanup data on dismount
  useEffect(() => {
    return () => {
      setImageData({
        id: null,
        name: "",
        description: "",
        imageDate: dayjs(),
        imageType: "",
        size: 0,
        resolution: "",
        urlImage: "",
        createdAt: null,
        aoi: {},
        originalCrs: "",
        file: null,
      });
    };
  }, []);

  const handleChangeImageName = (event: any) => {
    setImageData((previous: any) => {
      return {
        ...previous,
        name: event.target.value,
      };
    });
  };

  const handleChangeImageDescription = (event: any) => {
    setImageData((previous: any) => {
      return {
        ...previous,
        description: event.target.value,
      };
    });
  };

  const handleChangeImageDate = (newValue: any) => {
    setImageData((previous: any) => {
      return {
        ...previous,
        imageDate: newValue,
      };
    });
  };

  const handleChangeImageType = (event: any) => {
    setImageData((previous: any) => {
      return {
        ...previous,
        imageType: event.target.value,
      };
    });
  };

  const handleUploadFile = async (e: any) => {
    setImageData((previous: any) => {
      return {
        ...previous,
        file: e.nativeEvent.target.files[0],
      };
    });
  };

  const renderDatePickerField = (params: any) => {
    const hasError = !params.inputProps.value;
    return <TextField {...params} error={hasError} helperText={hasError && "Please select a date"} />;
  };

  const isSaveEnabled = () => {
    return !!imageData.file && !!imageData.name && !!imageData.imageType && !!imageData.imageDate;
  };

  const handleSaveImageClick = async () => {
    setOpenLoader(true);
    await uploadUserImage(
      imageData.name,
      imageData.imageDate,
      imageData.imageType,
      imageData.file,
      imageData.description,
    )
      .then((_) => {
        setSnackbarProps({ message: t("common.genericSuccessMesage"), severity: "success" });
        setOpenSnackbar(true);
        setIsAddView(false);
      })
      .catch((err) => {
        setSnackbarProps({
          message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
          severity: "error",
        });
        setOpenSnackbar(true);
      })
      .finally(() => {
        setOpenLoader(false);
      });
  };

  return (
    <Box sx={{ paddingTop: 4, paddingRight: 10, paddingBottom: 10 }}>
      <Stack spacing={5}>
        <Box>
          <Typography variant="body1">{t("images.name")}:</Typography>
          <TextField
            placeholder={t("images.namePlaceholder")}
            value={imageData.name}
            onChange={handleChangeImageName}
          />
        </Box>
        <Box>
          <Typography variant="body1">{t("images.description")}:</Typography>
          <TextField
            placeholder={t("images.descriptionPlaceholder")}
            value={imageData.description}
            onChange={handleChangeImageDescription}
            inputProps={{ maxLength: 140 }}
            fullWidth
          />
          <Box sx={{ display: "flex", width: "100%", justifyContent: "right" }}>
            <Typography variant="body1">{imageData.description.length}/140</Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="body1">{t("images.date")}:</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              value={imageData.imageDate}
              onChange={handleChangeImageDate}
              renderInput={(params) => renderDatePickerField(params)}
              disableFuture
            />
          </LocalizationProvider>
        </Box>
        <Box>
          <Typography variant="h6">{t("images.data")}</Typography>
          <FormControl variant="standard" sx={{ minWidth: 200 }}>
            <InputLabel style={{ fontSize: "12px" }}>{t("images.typeSelect")}</InputLabel>
            <Select value={imageData.imageType} name="asset" onChange={handleChangeImageType}>
              {Object.entries(IMAGE_TYPES).map(([key, value]) => {
                return (
                  <MenuItem value={key} key={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <Box>
          <Typography>
            {t("images.size")}: {imageData.file ? `${(imageData.file.size / 1024 / 1024).toFixed(2)} MB` : "--"}
          </Typography>
          <Typography>
            {t("images.fileName")}: {imageData.file ? imageData.file.name : "--"}
          </Typography>
        </Box>
        <Box>
          <Stack direction="row">
            <ButtonCustom
              label={t("common.uploadButton")}
              className="buttonStyleColoOutline"
              variant="contained"
              content={<VisuallyHiddenInput type="file" accept=".tiff, .tif" onChange={handleUploadFile} />}
            />
            <ButtonCustom
              label={t("common.saveButton")}
              className={isSaveEnabled() ? "buttonStyleColor" : "buttonStyleColoOutline"}
              disabled={!isSaveEnabled()}
              variant="contained"
              handleClick={handleSaveImageClick}
            />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}

export default ImageBuilder;
