import { Box, TextField } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import ButtonCustom from "../../../components/ui/button";
import useStyles from "../../../theme/styles";
import { useTranslation } from "react-i18next";

interface Step5Props {
  className: string;
  activeStep: number;
  stepIndex: number;
  projectDescription: string;
  setProjectDescription: (value: string) => void;
  handleStep: (step: number) => void;
}

function Step5(props: Step5Props) {
  const { className, activeStep, stepIndex, projectDescription, setProjectDescription, handleStep } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <div className={className}>
      <TextField
        label="Descripción"
        multiline
        disabled={activeStep !== stepIndex}
        rows={4}
        variant="outlined"
        value={projectDescription}
        onChange={(e) => setProjectDescription(e.target.value)}
        fullWidth
      />
      <Box>
        <div className={`${stepIndex !== activeStep ? classes.hideBtn : ""}`}>
          <ButtonCustom
            label={t("common.backButton")}
            startIcon={<ArrowBackIcon />}
            className="backButton"
            handleClick={handleStep(3)}
            tooltip={t("common.backTooltip")}
          />
        </div>
      </Box>
    </div>
  );
}

export default Step5;
