/* eslint-disable no-undef */
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent, Box } from "@mui/material";

import ButtonCustom from "../../../components/ui/button";
import useStyles from "../../../theme/styles";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function Step3(props: any) {
  const { activeStep, stepIndex, objective, setObjective, inputImages, isNextStepBlocked, handleStep } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  const handleGenericChangeSelect = (e: SelectChangeEvent, index: number) => {
    const { name, value } = e.target;

    const newObjective = objective.map((o: any, idx: number) => {
      if (index === idx) {
        if (name === "selectImageType") {
          return {
            ...o,
            [name]: value,
            selectSubImageType: "",
          };
        }
        return {
          ...o,
          [name]: value,
        };
      } else {
        return o;
      }
    });
    setObjective(newObjective);
  };

  return (
    <div className={`${activeStep === stepIndex ? classes.activeStep : classes.disabledStep}`}>
      {objective.map((o: any, objectiveIndex: number) => {
        return (
          <div key={`image-select-${objectiveIndex}`} className="containerStep3_select">
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 248 }}
              className="select_white select_width"
              disabled={activeStep !== stepIndex}
            >
              <InputLabel className="label_select">
                {t("newProject.selectImageType", { datasetNumber: objectiveIndex + 1 })}
              </InputLabel>
              <Select
                onChange={(e: SelectChangeEvent) => handleGenericChangeSelect(e, objectiveIndex)}
                name="selectImageType"
                className={`selectImage ${classes.selectColor}`}
                value={o.selectImageType || ""}
              >
                {Object.keys(inputImages[objectiveIndex]).map((img: any) => {
                  return <MenuItem value={img}>{t(`imageName.${img.toLowerCase()}`)}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 248 }}
              className="select_white select_width"
              disabled={activeStep !== stepIndex}
            >
              <InputLabel className="label_select">
                {t("newProject.selectSubimageType", { datasetNumber: objectiveIndex + 1 })}
              </InputLabel>
              <Select
                onChange={(e: SelectChangeEvent) => handleGenericChangeSelect(e, objectiveIndex)}
                name="selectSubImageType"
                className={`selectSubImage ${classes.selectColor}`}
                value={o.selectSubImageType || ""}
              >
                {(inputImages[objectiveIndex][objective[objectiveIndex].selectImageType] || []).map((subimage: any) => {
                  return <MenuItem value={subimage}>{t(`subimageName.${subimage.toLowerCase()}`)}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <ButtonCustom
              label={t("common.uploadButton")}
              className="buttonStyleColoOutline"
              variant="contained"
              tooltip={t("common.comingSoon")}
            />
          </div>
        );
      })}

      <Box sx={{ mb: 2 }}>
        <div className={`${stepIndex !== activeStep ? classes.hideBtn : ""}`}>
          <ButtonCustom
            label={t("common.backButton")}
            startIcon={<ArrowBackIcon />}
            className="backButton"
            handleClick={handleStep(1)}
            tooltip={t("common.backTooltip")}
          />
          <ButtonCustom
            label={t("common.continueButton")}
            className={isNextStepBlocked ? "buttonStyleColorDisable" : "buttonStyleColor"}
            variant="contained"
            handleClick={handleStep(3)}
            tooltip={t("common.continueTooltip", { extraText: "" })}
            disabled={isNextStepBlocked}
          />
        </div>
      </Box>
    </div>
  );
}

export default Step3;
