// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-dashboard.Mui-checked {
  color: #7024d1 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/lateralMenu/style.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;AAAJ","sourcesContent":[".radio-dashboard {\n  &.Mui-checked {\n    color: #7024d1 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
