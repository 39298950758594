// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-input .MuiInput-underline:after {
  border-bottom: 2px solid #7024d1 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/searchInput/style.scss"],"names":[],"mappings":"AACE;EACE,2CAAA;AAAJ","sourcesContent":[".search-input {\n  .MuiInput-underline:after {\n    border-bottom: 2px solid #7024d1 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
