import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent, Box } from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import { Tooltip, IconButton } from "@mui/material";
import ButtonCustom from "../../../components/ui/button";
import useStyles from "../../../theme/styles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

function Step1(props: any) {
  const initialOption = {
    asset: 4,
    attribute: null,
    dateStart: dayjs().subtract(60, "day"),
    dateEnd: dayjs(),
    selectLocation: "",
    selectImageType: "",
    selectSubImageType: "",
    output: "",
    cloudCoverage: 20,
    minArea: 25,
    spatialScale: 1,
  };

  const {
    className,
    objective,
    setObjective,
    maxPossibleAssets,
    activeStep,
    stepIndex,
    algorithmList,
    attributeListMaestros,
    assetList,
    inputImages,
    setInputImages,
    isNextStepBlocked,
    handleStep,
  } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  const handleGenericChangeSelect = (e: SelectChangeEvent, index: number) => {
    const { name, value } = e.target;

    const defaultImageType: any = {};

    const attr = attributeListMaestros.find((att: any) => att.id_algorithm_attribute === value);
    if (name === "attribute" && attr && attr.base_image) {
      const baseImage = JSON.parse(attr.base_image);
      const baseImageKeys = Object.keys(baseImage).reduce((acc: any, key) => {
        const children = baseImage[key];
        const trueChildren = Object.entries(children).reduce((childAcc: any, [childKey, childValue]) => {
          if (childValue === "True" || childKey === "default") {
            childAcc.push(childKey);
          }
          return childAcc;
        }, []);

        if (trueChildren.length > 0) {
          acc[key] = trueChildren;
        }
        return acc;
      }, {});
      defaultImageType["default"] = baseImage["default"];

      const newInputImages = inputImages.map((o: any, idx: number) => {
        return index === idx ? baseImageKeys : o;
      });
      setInputImages(newInputImages);
    }

    const newObjective = objective.map((o: any, idx: number) => {
      if (index === idx) {
        return {
          ...o,
          attribute: name === "asset" ? null : o.attribute,
          selectImageType: defaultImageType.default ? Object.keys(defaultImageType.default)[0] : "",
          selectSubImageType: defaultImageType.default ? Object.values(defaultImageType.default)[0] : "",
          [name]: value,
        };
      } else {
        return o;
      }
    });
    setObjective(newObjective);
  };

  const handleClickCloneUseCase = () => {
    if (objective.length <= maxPossibleAssets) {
      setObjective([...objective, initialOption]);
      setInputImages([...inputImages, {}]);
    }
  };

  const handleClickRemoveUseCase = (activeStep: number, stepIndex: number, index: number) => {
    if (activeStep === stepIndex) {
      setObjective(objective.filter((_: any, idx: number) => idx !== index));
      setInputImages(inputImages.filter((_: any, idx: number) => idx !== index));
    }
  };

  const getAvailabeAttributes = (assetIndex: number) => {
    if (algorithmList.length > 0 && objective[assetIndex]?.asset) {
      const listadoAlgorithmList = algorithmList.filter(
        (a: any) => a.id_algorithm_asset === objective[assetIndex]?.asset,
      );
      const list: any[] = [];
      listadoAlgorithmList.map((item: any) => {
        const e = attributeListMaestros.find((e: any) => e.id_algorithm_attribute === item.id_algorithm_attribute);
        if (e !== undefined) {
          list.push(e);
        }
      });
      const listSorted = list.sort((a, b) => a.name_attribute.localeCompare(b.name_attribute));
      return listSorted;
    }
    return [];
  };

  return (
    <div className={className}>
      {objective.map((o: any, objectiveIndex: number) => {
        return (
          <div key={`objective-${objectiveIndex}`}>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 200 }}
              className="select_white"
              disabled={activeStep !== stepIndex}
            >
              <InputLabel className="label_select" style={{ fontSize: "12px" }}>
                {/**TODO meter traducciones para esto?*/}
                {t("common.asset")}
              </InputLabel>
              <Select
                onChange={(e: SelectChangeEvent) => handleGenericChangeSelect(e, objectiveIndex)}
                value={o.asset || ""}
                name="asset"
                className={classes.selectColor}
              >
                {assetList &&
                  assetList.map((asset: any) => (
                    <MenuItem
                      value={asset.id_algorithm_asset || ""}
                      key={asset.id_algorithm_asset}
                      disabled={!asset.available}
                    >
                      {asset.name_asset}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 200 }}
              className="select_white"
              disabled={activeStep !== stepIndex}
            >
              <InputLabel className="label_select" style={{ fontSize: "12px" }}>
                {/**TODO meter traducciones para esto?*/}
                {t("common.attribute")}
              </InputLabel>
              <Select
                onChange={(e: SelectChangeEvent) => handleGenericChangeSelect(e, objectiveIndex)}
                value={o.attribute || ""}
                name="attribute"
                className={classes.selectColor}
              >
                {attributeListMaestros &&
                  getAvailabeAttributes(objectiveIndex).map((attribute: any) => {
                    return (
                      <MenuItem
                        value={attribute.id_algorithm_attribute || ""}
                        key={attribute.id_algorithm_attribute}
                        disabled={!attribute.available}
                      >
                        {attribute.name_attribute}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            {objectiveIndex > 0 ? (
              <Tooltip title={t("newProject.removeDatasetButton")} placement="top-end">
                <IconButton
                  disabled={activeStep !== stepIndex}
                  onClick={() => handleClickRemoveUseCase(activeStep, stepIndex, objectiveIndex)}
                  style={{ marginTop: "14px" }}
                >
                  <RemoveCircleOutlineIcon
                    className={`colorRed ${activeStep === stepIndex ? classes.cursorBtn : ""}`}
                  />
                </IconButton>
              </Tooltip>
            ) : null}
          </div>
        );
      })}
      {objective.length < maxPossibleAssets && (
        <ButtonCustom
          className={`step1_footer ${activeStep === stepIndex ? classes.cursorBtn : ""}`}
          handleClick={handleClickCloneUseCase}
          startIcon={<AddCircleOutlineIcon />}
          disabled={activeStep !== stepIndex}
          tooltip={t("common.addTooltip")}
          label={t("common.addButton")}
        />
      )}
      <Box sx={{ mb: 2 }}>
        <div className={`${stepIndex !== activeStep ? classes.hideBtn : ""}`}>
          <ButtonCustom
            label={t("common.continueButton")}
            className={isNextStepBlocked ? "buttonStyleColorDisable" : "buttonStyleColor"}
            variant="contained"
            handleClick={handleStep(1)}
            tooltip={t("common.continueTooltip", { extraText: t("common.continueAOI") })}
            disabled={isNextStepBlocked}
          />
        </div>
      </Box>
    </div>
  );
}

export default Step1;
