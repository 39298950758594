import DownloadIcon from "@mui/icons-material/Download";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, Menu, MenuItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import useStyles from "../../../theme/styles";
import { editComment, fetchData } from "../../../utils/requests";
import CircularWithValueLabel from "../../circularProgress";
import Loading from "../../loading";
import CheckboxCustom from "../../ui/checkbox";
import { EditTextField } from "../../ui/textField";
import { useState } from "react";

const IMAGE_DATE_ALGORITHMS: Array<string> = [
  "Water bodies",
  "Monitoring",
  "Night time lights",
  "Vegetation health",
  "Humidity",
  "Burned areas",
];

function Row(props: any) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    handleClick,
    handleClose,
    open,
    anchorEl,
    downloadActive,
    isSelected,
    keys,
    row,
    indx,
    extraParameters,
    showExtraParameters,
    handleChangeCheck,
    updateProject,
    setSnackbarProps,
    setOpenSnackbar,
  } = props;
  const [openExtraParams, setOpenExtraParams] = useState(false);

  const [editCommentMode, setEditCommentMode] = useState(false);
  const [newComment, setNewComment] = useState(row.comment);

  const saveComment = async () => {
    const fieldsToEdit = {
      id: row.id,
      comment: newComment,
    };
    const editedProject = await editComment(row.id_project, fieldsToEdit)
      .then((r) => {
        if (r.status == 200) {
          return r.data.project;
        }
        return null;
      })
      .catch((err) => {
        setSnackbarProps({
          message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
          severity: "error",
        });
        setOpenSnackbar(true);
        return null;
      });
    if (editedProject) {
      setSnackbarProps({ message: t("common.genericSuccessMesage"), severity: "success" });
      setOpenSnackbar(true);
      updateProject(editedProject);
      setEditCommentMode(false);
    }
  };

  const renderCellContent = (row: any, key: any) => {
    switch (key) {
      case "checkbox":
        return (
          <CheckboxCustom
            sx={{
              color: row.state === "Completed" ? "#c4c4c4" : "#7024d1",
              "&.Mui-checked": {
                color: "#7024d1",
              },
            }}
            handleChange={(_e: any) => handleChangeCheck(_e, row.id_dataset, row.id_project)}
            checked={row.checked}
            disabled={row.state === "Completed"}
          />
        );
      case "downloadAoi":
        return downloadActive ? (
          <Box sx={{ marginLeft: 0.5 }}>
            <Loading size={"1.5em"} />
          </Box>
        ) : (
          <div>
            <Button
              id="basic-button"
              className={`btn-download ${classes.btn}`}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              disableElevation
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              onClick={handleClick}
              startIcon={<DownloadIcon className={classes.btn_search} />}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem data-value={"kml"} key={"kml"} onClick={handleClose}>
                KML
              </MenuItem>
              <MenuItem data-value={"geojson"} key={"geojson"} onClick={handleClose}>
                GeoJSON
              </MenuItem>
              <MenuItem data-value={"csv"} key={"csv"} onClick={handleClose}>
                CSV
              </MenuItem>
              <MenuItem data-value={"shapefile"} key={"shapefile"} onClick={handleClose}>
                Shapefile
              </MenuItem>
              <MenuItem data-value={"dxf"} key={"dxf"} onClick={handleClose}>
                DXF
              </MenuItem>
            </Menu>
          </div>
        );
      case "state":
        if (row[key] !== null && row[key] === "Processing" && row.current_step >= 0 && isSelected)
          return (
            <CircularWithValueLabel
              initialProgress={row.current_step && row.total_steps ? (row.current_step / row.total_steps) * 100 : 0}
              progressFunction={() => getCurrentProgress(row.id_project, row.id_dataset)}
            />
          );
        return row[key];
      case "image_date": {
        if (!IMAGE_DATE_ALGORITHMS.includes(row["name_attribute"])) {
          return "NA";
        }
        return row["image_date"] ? new Date(row["image_date"]).toISOString().split("T")[0] : "-";
      }
      case "inputImage": {
        const imageName = row["image"]
          ? t(`imageName.${row["image"].toLowerCase()}`)
          : row["subimage"] === "user-image"
          ? t(`imageName.${row["subimage"].toLowerCase()}`)
          : "";
        const subimageName =
          row["subimage"] && row["subimage"] !== "user-image" ? t(`subimageName.${row["subimage"].toLowerCase()}`) : "";
        return `${imageName} ${subimageName ? `- ${subimageName}` : ""}`;
      }

      case "comment":
        return (
          <EditTextField
            editMode={editCommentMode}
            originalName={row.comment}
            newText={newComment}
            onChangeText={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNewComment(event.target.value);
            }}
            onEditClick={() => {
              setEditCommentMode(!editCommentMode);
            }}
            onSave={saveComment}
            onCancel={() => {
              setEditCommentMode(!editCommentMode);
            }}
          />
        );
      default:
        return row[key] !== null && row[key];
    }
  };

  const getCurrentProgress = async (idProject: number | null, idDataset: number | null) => {
    if (idProject && idDataset) {
      const id_user = localStorage.getItem("id_user");
      const result = await fetchData(`${id_user}/${idProject}/${idDataset}`, "GET_DATASET")
        .then(function (response) {
          const datasets = response.data.message;
          if (datasets) {
            const dataset = datasets[0];
            const currentStep = dataset.current_step ? dataset.current_step : 0;
            const totalSteps = dataset.total_steps;
            return totalSteps ? (currentStep / totalSteps) * 100 : 0;
          }
        })
        .catch(function () {
          return 0;
        });
      return result;
    }
    return 0;
  };

  const renderExtraParameters = (extraParameters: any) => {
    return (
      <Box sx={{ margin: 1, marginTop: 4 }}>
        <Typography variant="subtitle1" gutterBottom component="div">
          {t("newProject.step4Title")}
        </Typography>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              {extraParameters.map((parameter: any) => {
                return (
                  <TableCell sx={{ color: "#7024d1 !important", borderColor: "#7024d1 !important" }}>
                    {parameter.name}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {extraParameters.map((parameter: any) => {
                return <TableCell>{parameter.value}</TableCell>;
              })}
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    );
  };

  return (
    <>
      <TableRow key={indx} sx={{ height: 30, "&:last-child td, &:last-child th": { border: 0 } }}>
        {keys.map((key: any, index: number) => (
          <TableCell align="left" width={key === "checkbox" ? "5%" : "20%"} className={classes.tableC} key={index}>
            {renderCellContent(row, key)}
          </TableCell>
        ))}
        {showExtraParameters && extraParameters.length ? (
          <TableCell align="right" className={classes.tableC}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpenExtraParams(!openExtraParams)}>
              {openExtraParams ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : null}
      </TableRow>
      {showExtraParameters && extraParameters.length ? (
        <TableRow sx={{ border: 0 }}>
          <TableCell className={classes.tableC} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={openExtraParams} timeout="auto" unmountOnExit>
              {renderExtraParameters(extraParameters)}
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
}

export default Row;
