import { Modal, Box, Typography, Grid, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "../../../theme/styles";
import "./style.scss";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import { useEffect, useState } from "react";
import { editDatasetName, editProject } from "../../../utils/requests";
import { EditTextField } from "../../ui/textField";
import CustomSnackbar from "../../snackbar";

interface ModalCustomProps {
  open: boolean;
  setOpen: any;
  selectedProject: any;
  listDataset: any;
  updateListDataset: any;
  updateProject: any;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 490,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function EditProjectModal({
  open,
  setOpen,
  selectedProject,
  listDataset,
  updateListDataset,
  updateProject,
}: ModalCustomProps) {
  const classes = useStyles();

  const { t } = useTranslation();

  const [snackbarProps, setSnackbarProps] = useState({ message: "", severity: "" });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [newProjectName, setNewProjectName] = useState(selectedProject.name_project);
  const [editProjectMode, setEditProjectMode] = useState(false);
  const [newDescription, setNewDescription] = useState(selectedProject.description);
  const [editProjectDescriptionMode, setEditProjectDescriptionMode] = useState(false);
  const [newDatasetNames, setNewDatasetNames] = useState(
    listDataset.reduce((acc: any, dataset: any) => {
      return { ...acc, [dataset.id_dataset]: { name: dataset.dataset_name, editMode: false } };
    }, {}),
  );

  const handleClose = () => setOpen(false);

  const saveDatasetName = async (datasetId: any) => {
    const editedDataset = await editDatasetName(selectedProject.id_project, datasetId, newDatasetNames[datasetId].name)
      .then((r) => {
        if (r.status == 200) {
          return r.data.dataset;
        }
        return null;
      })
      .catch((err) => {
        setSnackbarProps({
          message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
          severity: "error",
        });
        setOpenSnackbar(true);
        return null;
      });
    if (editedDataset) {
      setSnackbarProps({ message: t("common.genericSuccessMesage"), severity: "success" });
      setOpenSnackbar(true);
      updateListDataset(editedDataset);
      setNewDatasetNames({
        ...newDatasetNames,
        [datasetId]: {
          name: newDatasetNames[datasetId].name,
          editMode: newDatasetNames[datasetId] ? !newDatasetNames[datasetId].editMode : false,
        },
      });
    }
  };

  const saveProject = async () => {
    const fieldsToEdit = {
      project_name: newProjectName !== selectedProject.name_project ? newProjectName : null,
      description: newDescription !== selectedProject.description ? newDescription : null,
    };
    const editedProject = await editProject(selectedProject.id_project, fieldsToEdit)
      .then((r) => {
        if (r.status == 200) {
          return r.data.project;
        }
        return null;
      })
      .catch((err) => {
        setSnackbarProps({
          message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
          severity: "error",
        });
        setOpenSnackbar(true);
        return null;
      });
    if (editedProject) {
      setSnackbarProps({ message: t("common.genericSuccessMesage"), severity: "success" });
      setOpenSnackbar(true);
      updateProject(editedProject);
      setEditProjectMode(false);
      setEditProjectDescriptionMode(false);
    }
  };

  useEffect(() => {
    setNewProjectName(selectedProject.name_project);
    setNewDescription(selectedProject.description);
  }, [selectedProject]);

  useEffect(() => {
    setNewDatasetNames(
      listDataset.reduce((acc: any, dataset: any) => {
        return {
          ...acc,
          [dataset.id_dataset]: {
            name: dataset.dataset_name,
            editMode: false,
          },
        };
      }, {}),
    );
  }, [listDataset]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon onClick={handleClose} className={`${classes.iconClose}`} />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit project information
          </Typography>

          <Divider variant="middle" sx={{ marginBottom: "20px" }} />

          <Stack spacing={2}>
            <Grid container justifyItems={"left"} alignItems={"center"}>
              <Grid item sx={{ marginRight: "10px" }}>
                <Typography id="modal-modal-title" variant="body1" component="h2">
                  Project name:
                </Typography>
              </Grid>
              <EditTextField
                editMode={editProjectMode}
                originalName={selectedProject.name_project}
                newText={newProjectName}
                onChangeText={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewProjectName(event.target.value);
                }}
                onEditClick={() => {
                  setEditProjectMode(!editProjectMode);
                }}
                onSave={saveProject}
                onCancel={() => {
                  setEditProjectMode(!editProjectMode);
                }}
              />
            </Grid>
            <Grid container justifyItems={"left"} alignItems={"center"}>
              <Grid item sx={{ marginRight: "10px" }}>
                <Typography id="modal-modal-title" variant="body1" component="h2">
                  Description:
                </Typography>
              </Grid>
              <EditTextField
                editMode={editProjectDescriptionMode}
                originalName={selectedProject.description}
                newText={newDescription}
                onChangeText={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewDescription(event.target.value);
                }}
                onEditClick={() => {
                  setEditProjectDescriptionMode(!editProjectDescriptionMode);
                }}
                onSave={saveProject}
                onCancel={() => {
                  setEditProjectDescriptionMode(!editProjectDescriptionMode);
                }}
              />
            </Grid>
            {listDataset.map((dataset: any, idx: number) => {
              return (
                <Grid container justifyItems={"left"} alignItems={"center"}>
                  <Grid item sx={{ marginRight: "10px" }}>
                    <Typography id="modal-modal-title" variant="body1" component="h2">
                      Dataset {idx + 1}:
                    </Typography>
                  </Grid>
                  <EditTextField
                    editMode={!!newDatasetNames[dataset.id_dataset]?.editMode}
                    originalName={dataset.dataset_name}
                    newText={newDatasetNames[dataset.id_dataset] ? newDatasetNames[dataset.id_dataset].name : ""}
                    onChangeText={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setNewDatasetNames({
                        ...newDatasetNames,
                        [dataset.id_dataset]: { name: event.target.value, editMode: true },
                      });
                    }}
                    onEditClick={() => {
                      setNewDatasetNames({
                        ...newDatasetNames,
                        [dataset.id_dataset]: {
                          name: newDatasetNames[dataset.id_dataset].name,
                          editMode: !newDatasetNames[dataset.id_dataset]?.editMode,
                        },
                      });
                    }}
                    onSave={() => saveDatasetName(dataset.id_dataset)}
                    onCancel={() => {
                      setNewDatasetNames({
                        ...newDatasetNames,
                        [dataset.id_dataset]: {
                          name: newDatasetNames[dataset.id_dataset].name,
                          editMode: !newDatasetNames[dataset.id_dataset]?.editMode,
                        },
                      });
                    }}
                  />
                </Grid>
              );
            })}
          </Stack>
        </Box>
      </Modal>
      <CustomSnackbar snackbarProps={snackbarProps} openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} />
    </>
  );
}

export default EditProjectModal;
