import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import { fromUrl, fromArrayBuffer } from "geotiff";

const TiffViewer = (props) => {
  const { file, url } = props;

  const canvasRef = useRef(null);

  const getImageDataRGB = (rasterData, width, height, context, numberBands) => {
    // Calcular el mínimo y máximo manualmente
    const applyContrastStretch = (data) => {
      let minVal = Infinity;
      let maxVal = -Infinity;
      const nonZeroData = data.filter((value) => value !== 0);
      for (const value of nonZeroData) {
        if (value < minVal) minVal = value;
        if (value > maxVal) maxVal = value;
      }

      return data.map((value) => ((value - minVal) / (maxVal - minVal)) * 255);
    };

    const normalizedValues = {
      red: [],
      green: [],
      blue: [],
    };

    if (numberBands === 1) {
      const grey = applyContrastStretch(rasterData);
      normalizedValues.red = grey;
      normalizedValues.green = grey;
      normalizedValues.blue = grey;
    } else {
      const red = applyContrastStretch(rasterData[0]);
      const green = applyContrastStretch(rasterData[1]);
      const blue = applyContrastStretch(rasterData[2]);
      normalizedValues.red = red;
      normalizedValues.green = green;
      normalizedValues.blue = blue;
    }

    // Crear una imagen en escala de grises o en color
    const imgData = context.createImageData(width, height);
    for (let i = 0; i < width * height; i++) {
      imgData.data[i * 4 + 0] = normalizedValues.red[i]; // Rojo (Banda 1)
      imgData.data[i * 4 + 1] = normalizedValues.green[i]; // Verde (Banda 2)
      imgData.data[i * 4 + 2] = normalizedValues.blue[i]; // Azul (Banda 3)
      imgData.data[i * 4 + 3] = 255; // Alpha (opacidad total)
    }

    return imgData;
  };

  const drawGeotiffScaled = async (tiff) => {
    const image = await tiff.getImage();
    const numBands = image.getSamplesPerPixel();

    // Obtener dimensiones y datos del raster
    const width = image.getWidth();
    const height = image.getHeight();
    const rasterData = await image.readRasters(numBands === 1 ? { interleave: true } : { samples: [0, 1, 2] });

    const tempCanvas = document.createElement("canvas");
    const tempCtx = tempCanvas.getContext("2d");
    tempCanvas.width = width;
    tempCanvas.height = height;

    const imageData = getImageDataRGB(rasterData, width, height, tempCtx, numBands);

    // Renderizar la imagen en el <canvas> temporal
    tempCtx.putImageData(imageData, 0, 0);

    // Escalar la imagen en el <canvas> principal
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const canvasWidth = canvas.offsetWidth;
    const canvasHeight = canvas.offsetHeight;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    ctx.drawImage(tempCanvas, 0, 0, canvasWidth, canvasHeight);
  };

  const loadFromUrl = async () => {
    try {
      // Cargar el archivo GeoTIFF desde la URL
      const tiff = await fromUrl(url);
      await drawGeotiffScaled(tiff);
    } catch (error) {
      console.error("Error al cargar el GeoTIFF:", error);
    }
  };

  const loadFromFile = () => {
    const reader = new FileReader();
    reader.onload = async () => {
      try {
        const arrayBuffer = reader.result;
        const tiff = await fromArrayBuffer(arrayBuffer);
        await drawGeotiffScaled(tiff);
      } catch (err) {
        console.log("Error al cargar la imagen TIFF.");
        console.error(err);
      }
    };
    reader.onerror = () => console.log("Error al leer el archivo.");
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (url) {
      loadFromUrl();
    } else if (file && (file.type === "image/tiff" || file.type === "image/tif")) {
      loadFromFile();
    } else {
      console.log("Por favor, selecciona un archivo TIFF.");
    }
  }, [file, url]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const resizeCanvas = () => {
        const { clientWidth, clientHeight } = canvas.parentElement;

        const context = canvas.getContext("2d");
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

        canvas.style.width = `${clientWidth}px`;
        canvas.style.height = `${clientHeight}px`;

        canvas.width = clientWidth;
        canvas.height = clientHeight;

        context.putImageData(imageData, 0, 0);
      };

      resizeCanvas();
      window.addEventListener("resize", resizeCanvas);

      return () => window.removeEventListener("resize", resizeCanvas);
    }
  }, []);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <canvas ref={canvasRef} style={{ display: "block" }} />
    </Box>
  );
};

export default TiffViewer;
