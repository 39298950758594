import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { _MapContext as MapContext } from "react-map-gl";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

const Geocoder = ({ mapObject, label, changeViewport }) => {
  const geocoderContainerRef = useRef(null);

  useEffect(() => {
    const geocoderObject = new MapboxGeocoder({
      accessToken: "pk.eyJ1IjoianNhbnRvc20iLCJhIjoiY2tremIxMzN4MTg2bTJybzR5c3N6bTVhdyJ9.hsEiYUhOE-U8t0iCUY1wGQ",
      mapboxgl: MapContext,
      marker: false,
      placeholder: label,
      language: "en-EN",
      getItemValue: (item) => {
        changeViewport(item.center);

        return item.place_name;
      },
    });

    geocoderContainerRef.current.appendChild(geocoderObject.onAdd(mapObject.map));
    geocoderObject.on("clear", () => {
      changeViewport([]);
    });
  }, []);

  return <div ref={geocoderContainerRef}></div>;
};

Geocoder.propTypes = {
  mapObject: PropTypes.any,
  changeViewport: PropTypes.func,
  geolocation: PropTypes.func,
  label: PropTypes.string,
};

export default Geocoder;
