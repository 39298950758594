import { Box, CircularProgress } from "@mui/material";
import "./style.scss";

function Loading(props: any) {
  const { size } = props;
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress
        sx={{
          color: "#7024D1",
        }}
        size={size ? size : 70}
      />
    </Box>
  );
}

export default Loading;
