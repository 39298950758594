import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Typography,
  Modal,
  Tab,
  Tabs,
  Tooltip,
  Button,
  Menu,
  MenuItem,
  Pagination,
  IconButton,
  TextField,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SearchIcon from "@mui/icons-material/Search";
import { useHistory } from "react-router-dom";
import TableC from "../../components/table";
import { CircularProgress } from "@mui/material";
import { ModalCustom, EditProjectModal } from "../../components/modal";
import CheckboxCustom from "../../components/ui/checkbox";
import ButtonCustom from "../../components/ui/button";
import {
  getProjectByUser,
  deleteProject,
  fetchData,
  deleteDataset,
  getNumberPages,
  downloadAoi,
  runDataset,
  addComment,
} from "../../utils/requests";
import { Project } from "../../models/project";
import { Dataset } from "../../models/dataset";
import SearchInputCustom from "../../components/ui/searchInput";
import fileDownload from "js-file-download";
import { useTranslation } from "react-i18next";
import CustomSnackbar from "../../components/snackbar";
import { navigation } from "../../utils/navigation";
import "./style.scss";

const FILE_EXTENSION_TRANSLATIONS: any = {
  shapefile: ".zip",
  kml: ".kml",
  geojson: ".geojson",
  csv: ".csv",
  dxf: ".dxf",
};

function Projects() {
  const history = useHistory();
  localStorage.setItem("editName", "");
  localStorage.setItem("editAsset", "");
  localStorage.setItem("editAttribute", "");
  localStorage.setItem("openPanelLeft", "");

  const { t } = useTranslation();

  const header = [t("common.status"), t("projects.headerArea"), t("projects.headerAOI")];
  const headerDataset = [
    "",
    t("projects.headerCreationDate"),
    t("projects.headerFileName"),
    t("common.status"),
    t("projects.headerImageDate"),
    t("projects.inputImage"),
  ];

  const queryParameters = new URLSearchParams(window.location.search);
  const projectNameFilter = queryParameters.get("projectName");

  const [openLoader, setOpenLoader] = useState(false);
  const [openModalRun, setOpenModalRun] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalDeleteDataset, setOpenModalDeleteDataset] = useState(false);
  const [openModalEditProject, setOpenModalEditProject] = useState(false);
  const [openModalAddComment, setOpenModalAddComment] = useState(false);

  const [activeRemoveDataset, setActiveRemoveDataset] = useState(false);
  const [activeExecuteDataset, setActiveExecuteDataset] = useState(false);

  const [listProject, setListProject] = useState([]);
  const [searched, setSearched] = useState<string>(projectNameFilter ? projectNameFilter : "");
  const [valueTab, setValueTab] = useState<string>("stats");
  const [listDataset, setListDataset] = useState<any>([]);

  const [orderByProject, setOrderByProject] = useState("creation_date");

  const [orderByProjectList, setOrderByProjectList] = useState<any>({
    ["creation_date"]: {
      desc: true,
    },
    ["last_execution_date"]: {
      desc: true,
    },
    ["name_project"]: {
      desc: true,
    },
  });

  const [listIdProjectRemove, setListIdProjectRemove] = useState<any>([]);
  const [idProjectByDatasetRemove, setIdProjectByDatasetRemove] = useState<any>();
  const [numberPages, setNumberPages] = useState(1);
  const [page, setPage] = useState(1);
  const [newComment, setNewComment] = useState("");

  const [snackbarProps, setSnackbarProps] = useState({ message: "", severity: "" });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const formatValue = (value: any) => {
    const parsed = parseFloat(value);
    const valueInKilometers = parsed / 10 ** 6;

    if (parseFloat(valueInKilometers.toFixed(3)) === 0) {
      const formated = parsed
        .toFixed(3)
        .replace(".", ",")
        .replace(/\B(?=(\d{5})+(?!\d))/g, ".");
      return (
        <Typography>
          {formated} (m<sup>2</sup>)
        </Typography>
      );
    } else {
      const formated = valueInKilometers
        .toFixed(3)
        .replace(".", ",")
        .replace(/\B(?=(\d{5})+(?!\d))/g, ".");
      return (
        <Typography>
          {formated} (km<sup>2</sup>)
        </Typography>
      );
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValueTab(newValue);
  };

  const getProjectList = async (orderBy: string | null = null, isDesc: boolean | null = null) => {
    try {
      const projectName = searched ? searched : null;
      // Only gets orderBy and isDesc from params if project oreder changes because state may not be correctly updated
      const orderByParam = orderBy == null ? orderByProject : orderBy;
      const isOrderByDesc = isDesc == null ? orderByProjectList[orderByProject].desc : isDesc;

      await getNumberPages(projectName)
        .then(function (response) {
          if (response.status === 200) {
            setNumberPages(response.data.number_of_pages);
          }
        })
        .catch(function (err) {
          setOpenLoader(false);
          setSnackbarProps({
            message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
            severity: "error",
          });
          setOpenSnackbar(true);
        });
      await getProjectByUser(projectName ? 1 : page, projectName, orderByParam, isOrderByDesc)
        .then(function (response) {
          if (response.status === 200) {
            setOpenLoader(false);
            const listProjectCheck = response.data.message.map((p: any) => {
              return (p = {
                ...p,
                checked: false,
              });
            });
            setListProject(listProjectCheck);
          } else {
            setOpenLoader(false);
            setListProject([]);
          }
        })
        .catch(function (err) {
          // some error here
          setOpenLoader(false);
          setListProject([]);
          if (err.response.status !== 404) {
            setSnackbarProps({
              message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
              severity: "error",
            });
            setOpenSnackbar(true);
          }
        });
    } catch (e) {
      // some error here
    }
    localStorage.removeItem("idProject");
    localStorage.removeItem("nameProject");
  };

  useEffect(() => {
    setOpenLoader(true);
    getProjectList();
  }, []);

  const requestSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchedVal = event.target.value;
    setSearched(searchedVal);
  };

  const handleOpenExecute = async (id_project: number) => {
    //recojer los checkbox de ese proyecto que estan checked
    const datasetSelect = listDataset.filter((v) => v.checked && v.id_project === id_project);
    const datasetsToSend = [];
    datasetSelect.forEach((d) => {
      const basemap = d.input_alg_parameters !== null ? d.input_alg_parameters.basemap_name : false;

      const optionalParameters = {
        area: undefined,
        spatial_scale: false,
      };

      if (
        d.name_attribute === "Solar Panels" ||
        d.name_attribute === "Powerblocks" ||
        d.name_attribute === "Roads (Solar Plants)" ||
        d.name_attribute === "Solar Panels Plants" ||
        d.name_attribute === "Solar Trackers Plants" ||
        d.name_attribute === "Wind Turbines" ||
        d.name_attribute === "Vehicles" ||
        d.name_attribute === "Roads" ||
        d.name_attribute === "High Tension Towers" ||
        d.name_attribute === "Medium Tension Towers" ||
        d.name_attribute === "Trenches"
      ) {
        //send params for download image first
        optionalParameters.area = d.input_alg_parameters.mask.features[0];
      } else if (d.name_attribute === "Segmentation") {
        //send params for download image first
        optionalParameters.area = d.input_alg_parameters.mask.features[0];
        optionalParameters.spatial_scale = true;
      }

      const datasetToSend = { ...d, basemap_name: basemap, ...optionalParameters };
      datasetsToSend.push(datasetToSend);
    });

    runDataset(id_project, datasetsToSend).catch(function (err) {
      setSnackbarProps({
        message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
        severity: "error",
      });
      setOpenSnackbar(true);
    });

    const id_user = localStorage.getItem("id_user");
    await fetchData(`${id_user}/${id_project}`, "GET_DATASET")
      .then(function (response) {
        const listDatasetCheck = response.data.message.map((d: any) => {
          return (d = {
            ...d,
            checked: false,
            display: true,
          });
        });
        setListDataset(listDatasetCheck);
        setOpenLoader(false);
      })
      .catch(function (err) {
        setListDataset([]);
        setOpenLoader(false);
        setSnackbarProps({
          message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
          severity: "error",
        });
        setOpenSnackbar(true);
      });

    setOpenModalRun(true);
  };

  const deleteDatasetByCheck = async () => {
    setOpenLoader(true);
    //recojer los checkbox de ese proyecto que estan checked
    const datasetSelect = listDataset.filter((v) => v.checked && v.id_project === idProjectByDatasetRemove);
    const arrayIdDatasetDelete = [];
    datasetSelect.forEach(async (d: Dataset) => {
      arrayIdDatasetDelete.push(d.id_dataset);
    });
    datasetSelect.length === 0 && setOpenLoader(false);
    const listDatasetNew = [];
    datasetSelect.forEach(async (d: Dataset) => {
      await deleteDataset(idProjectByDatasetRemove, d.id_dataset)
        .then(async function (response) {
          if (response.status === 200) {
            listDataset.forEach((dS) => {
              dS.checked = false;
              dS.display = true;
              if (!arrayIdDatasetDelete.includes(dS.id_dataset)) {
                listDatasetNew.push(dS);
              }
            });
          }
          setListDataset(listDatasetNew);
          handleChangePanel(idProjectByDatasetRemove);
          // Check if there are still datasets on the project
          const remainingDatasets = listDatasetNew.filter((d: Dataset) => d.id_project === idProjectByDatasetRemove);
          if (remainingDatasets.length === 0) {
            // Delete the project
            await deleteProject(idProjectByDatasetRemove)
              .then(function (response) {
                if (response.status === 200) {
                  getProjectList();
                }
              })
              .catch(function (err) {
                setSnackbarProps({
                  message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
                  severity: "error",
                });
                setOpenSnackbar(true);
              });
          }
          setOpenLoader(false);
        })
        .catch(function (err) {
          setListDataset([]);
          setOpenLoader(false);
          setSnackbarProps({
            message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
            severity: "error",
          });
          setOpenSnackbar(true);
        });
    });

    setIdProjectByDatasetRemove(null);
    setOpenModalDeleteDataset(false);
  };

  const handleOpenDeleteModal = () => setOpenModalDelete(true);
  const handleCloseModal = () => setOpenLoader(false);

  const [expanded, setExpanded] = useState<string | false>(false);

  const [valueCheckbox, setValueCheckbox] = useState<boolean | undefined>(false);
  const [activeRemove, setActiveRemove] = useState<boolean | undefined>(false);
  const [selectedProject, setSelectedProject] = useState<number | null>(null);

  const handleChangePanel = (panel: number) => async (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? `panel${panel}` : false);
    const id_user = localStorage.getItem("id_user");

    if (isExpanded) {
      setOpenLoader(true);
      await fetchData(`${id_user}/${panel}`, "GET_DATASET")
        .then(function (response) {
          const listDatasetCheck = response.data.message.map((d: any) => {
            return (d = {
              ...d,
              checked: false,
              display: true,
            });
          });
          setListDataset(listDatasetCheck);
          setOpenLoader(false);
          setSelectedProject(panel);
        })
        .catch(function (err) {
          setListDataset([]);
          setOpenLoader(false);
          setSnackbarProps({
            message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
            severity: "error",
          });
          setOpenSnackbar(true);
        });
    } else {
      setSelectedProject(null);
    }
  };

  const handleChangeAllCheckbox = () => {
    setValueCheckbox(!valueCheckbox);
    setActiveRemove(!valueCheckbox);

    const listProjectArray = [...listProject];
    const listArray = [...listIdProjectRemove];
    listProjectArray.forEach((p: Project, i: number) => {
      listProjectArray[i].checked = !valueCheckbox;
      listArray.push(p.id_project);
    });

    if (!valueCheckbox === false) {
      setListIdProjectRemove([]);
    } else {
      setListIdProjectRemove(listArray);
    }

    setListProject(listProjectArray);
  };

  const handleCheckRemove = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    e.stopPropagation();

    const listProjectArray = [...listProject];
    listProjectArray[index].checked = e.target.checked;
    setListProject(listProjectArray);

    const listArray = [...listIdProjectRemove];
    const value = parseInt(e.target.value);
    if (e.target.checked) {
      if (listArray.indexOf(value) !== -1) {
        listArray.splice(listArray.indexOf(value), 1);
        setListIdProjectRemove(listArray);
      } else {
        listArray.push(value);
        setListIdProjectRemove(listArray);
      }
    } else {
      if (listArray.indexOf(value) !== -1) {
        listArray.splice(listArray.indexOf(value), 1);
        setListIdProjectRemove(listArray);
      }
    }

    if (listArray.length > 0) {
      setActiveRemove(true);
    } else {
      setActiveRemove(false);
    }
  };

  const handleClickRemoveDataset = (id_project: number) => {
    setIdProjectByDatasetRemove(id_project);
    setOpenModalDeleteDataset(true);
  };

  const handleChangeCheck = (e: any, id_dataset: any, id_project: any) => {
    const listDatasetNew: any = [];
    Object.values(listDataset).forEach((dataset: any) => {
      if (dataset.id_project === id_project && dataset.id_dataset === id_dataset) {
        dataset.checked = e.target.checked;
      }
      listDatasetNew.push(dataset);
    });
    setListDataset(listDatasetNew);

    let executeDataset = false;
    let removeDataset = false;
    Object.values(listDataset).forEach((dataset: any) => {
      if (dataset.checked === true) {
        removeDataset = dataset.checked;
        if (dataset.state === "Completed") {
          executeDataset = false;
        } else executeDataset = true;
      }
    });

    setActiveRemoveDataset(removeDataset);
    setActiveExecuteDataset(executeDataset);
  };

  const deleteProjectByCheck = async () => {
    setOpenLoader(true);
    await Promise.all(
      listIdProjectRemove.map(async (projectId: number) => {
        await deleteProject(projectId)
          .then(async function () {
            await getProjectList();
            setListIdProjectRemove([]);
            setOpenModalDelete(false);
            setActiveRemove(false);
            setOpenLoader(false);
          })
          .catch(async function (err) {
            setSnackbarProps({
              message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
              severity: "error",
            });
            setOpenSnackbar(true);
            return false;
          });
      }),
    );
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOrderByDB = (type: string) => {
    const orderBy = type;
    const isDesc = !orderByProjectList[type].desc;

    setOrderByProjectList((prevState: any) => ({
      ...prevState,
      [orderBy]: {
        desc: isDesc,
      },
    }));

    setOrderByProject(orderBy);

    setOpenLoader(true);
    getProjectList(orderBy, isDesc);
  };

  window.addEventListener("scroll", () => {
    open && setAnchorEl(null);
  });

  const handleChangePagination = async (event: React.ChangeEvent<unknown>, value: number) => {
    setOpenLoader(true);
    setPage(value);
    const projectName = projectNameFilter ? projectNameFilter : null;
    await getProjectByUser(value, projectName)
      .then(function (response) {
        if (response.status === 200) {
          setOpenLoader(false);
          const listProjectCheck = response.data.message.map((p: any) => {
            return (p = {
              ...p,
              checked: false,
            });
          });

          //ordenar por last_executed
          const listProjectCheck1 = listProjectCheck.sort((x: any, y: any) => {
            const xDate = new Date(
              x.creation_date.split("-")[1] + "-" + x.creation_date.split("-")[0] + "-" + x.creation_date.split("-")[2],
            );
            const yDate = new Date(
              y.creation_date.split("-")[1] + "-" + y.creation_date.split("-")[0] + "-" + y.creation_date.split("-")[2],
            );
            return yDate.getTime() - xDate.getTime();
          });
          setListProject(listProjectCheck1);
        } else {
          setOpenLoader(false);
          setListProject([]);
        }
      })
      .catch(function (err) {
        // some error here
        setOpenLoader(false);
        setListProject([]);
        setSnackbarProps({
          message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
          severity: "error",
        });
        setOpenSnackbar(true);
      });
  };

  const handleSearchProject = () => {
    history.push({
      pathname: navigation.projects,
      search: searched ? `?projectName=${searched}` : "",
    });
    getProjectList();
  };

  const [downloadActive, setDownloadActive] = useState(false);

  const handleDownloadAoiClick = async (project: any, fileFormat: string) => {
    const id_user = localStorage.getItem("id_user");
    setDownloadActive(true);
    await downloadAoi(`${id_user}/${project.id_project}`, fileFormat)
      .then(function (response) {
        if (response.status === 200) {
          fileDownload(response.data, `${project.name_project}${FILE_EXTENSION_TRANSLATIONS[fileFormat]}`);
        }
      })
      .catch(function (err) {
        setSnackbarProps({
          message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
          severity: "error",
        });
        setOpenSnackbar(true);
      });
    setDownloadActive(false);
  };

  const datasetsWithParameters = () => {
    const hasParameters = listDataset.reduce((acc: any, dataset: any) => {
      return (
        acc ||
        !!dataset.input_alg_parameters?.start_date ||
        !!dataset.input_alg_parameters?.end_date ||
        !!dataset.input_alg_parameters?.cloud_percentage ||
        !!dataset.input_alg_parameters?.spatial_scale ||
        !!dataset.input_alg_parameters?.minimum_segment_size
      );
    }, false);
    return hasParameters;
  };

  const handleClickEditProject = () => {
    setOpenModalEditProject(true);
  };

  const handleClickAddComment = () => {
    setOpenModalAddComment(true);
  };

  const getSelectedProject = () => {
    const projectToEdit = listProject.reduce((acc, project) => {
      if (project.id_project == selectedProject) return project;
      return acc;
    }, {});
    return projectToEdit;
  };

  const updateListDataset = (newDataset: any) => {
    const newListDataset = listDataset.map((dataset: any) => {
      if (dataset.id_dataset === newDataset.id_dataset) return { ...dataset, ...newDataset };
      return dataset;
    });
    setListDataset(newListDataset);
  };

  const updateProject = (newProject: any) => {
    const newProjectList = listProject.map((project: any) => {
      if (project.id_project === newProject.id_project) return { ...project, ...newProject };
      return project;
    });

    setListProject(newProjectList);
  };

  const handleAddComment = async () => {
    if (selectedProject) {
      const editedProject = await addComment(selectedProject, newComment)
        .then((r) => {
          if (r.status === 200) {
            return r.data.project;
          }
          return null;
        })
        .catch((err) => {
          setSnackbarProps({
            message: err.response?.data ? err.response.data.message : t("common.genericErrorMessage"),
            severity: "error",
          });
          setOpenSnackbar(true);
          return null;
        });
      if (editedProject) {
        setSnackbarProps({ message: t("common.genericSuccessMesage"), severity: "success" });
        setOpenSnackbar(true);
        updateProject(editedProject);
        setOpenModalAddComment(false);
        setNewComment("");
      }
    }
  };

  return (
    <div className="container">
      <Modal
        open={openLoader}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="containerLoader">
          <CircularProgress
            sx={{
              color: "#7024D1",
            }}
            size={70}
          />
        </div>
      </Modal>
      <ModalCustom
        open={openModalRun}
        setOpen={setOpenModalRun}
        content={
          <div>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("projects.successExecuteProject")}
            </Typography>
          </div>
        }
        hasButton={false}
      />
      <ModalCustom
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        content={
          <>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("projects.deleteProject")}
            </Typography>
          </>
        }
        accept={deleteProjectByCheck}
      />
      <ModalCustom
        open={openModalDeleteDataset}
        setOpen={setOpenModalDeleteDataset}
        content={
          <>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("projects.deleteDatasetConfirmation")}
            </Typography>
          </>
        }
        accept={deleteDatasetByCheck}
      />
      <EditProjectModal
        open={openModalEditProject}
        setOpen={setOpenModalEditProject}
        selectedProject={openModalEditProject && getSelectedProject()}
        listDataset={listDataset}
        updateListDataset={updateListDataset}
        updateProject={updateProject}
      />

      <ModalCustom
        open={openModalAddComment}
        setOpen={setOpenModalAddComment}
        content={
          <Stack spacing={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add comment
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              maxRows={4}
              onClick={(event) => {
                event.stopPropagation();
              }}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  fontSize: "14px",
                },
                pointerEvents: "auto",
              }}
              value={newComment}
              onChange={(event) => setNewComment(event.target.value)}
            />
          </Stack>
        }
        accept={handleAddComment}
        labelOK={"common.addButton"}
      />
      {listProject.length > 0 ? (
        <div className="body_container">
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            {t("projects.projectCount", {
              projectCount: listProject.length,
              projectPlural: listProject.length === 1 ? "" : "s",
            })}
          </Typography>
          <SearchInputCustom
            icon={
              <IconButton onClick={handleSearchProject}>
                <SearchIcon />
              </IconButton>
            }
            handleSearch={handleSearchProject}
            value={searched}
            onChange={requestSearch}
          />
          <div>
            <label>{t("projects.selectAll")}</label>
            <Checkbox
              onChange={handleChangeAllCheckbox}
              checked={valueCheckbox}
              sx={{
                color: "#7024d1",
                "&.Mui-checked": {
                  color: "#7024d1",
                },
              }}
            />
            <ButtonCustom
              label={t("common.deleteButton")}
              className={`${activeRemove ? "buttonStyleColor" : "buttonStyleColoOutline"}`}
              variant="contained"
              handleClick={handleOpenDeleteModal}
              tooltip={t("projects.deleteProjectTooltip")}
              disabled={!activeRemove}
            />

            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              style={{ float: "right", color: "black", textTransform: "none" }}
            >
              {orderByProjectList[orderByProject].desc ? (
                <ArrowDownwardIcon style={{ color: "rgba(0, 0, 0, 0.3)" }} />
              ) : (
                <ArrowUpwardIcon style={{ color: "rgba(0, 0, 0, 0.3)" }} />
              )}
              {t("common.orderByText", { orderCriteria: t(`common.${orderByProject}`) })}
              <ExpandMoreIcon />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              disableScrollLock={true}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <Tooltip
                title={t("common.orderByText", { orderCriteria: t(`common.creation_date`) })}
                placement="right-start"
              >
                <MenuItem onClick={() => handleOrderByDB("creation_date")}>
                  {t(`common.creation_date`)}
                  {orderByProject === "creation_date" && (
                    <div>
                      {orderByProjectList["creation_date"].desc ? (
                        <ArrowDownwardIcon style={{ color: "rgba(0, 0, 0, 0.3)" }} />
                      ) : (
                        <ArrowUpwardIcon style={{ color: "rgba(0, 0, 0, 0.3)" }} />
                      )}
                    </div>
                  )}
                </MenuItem>
              </Tooltip>
              <Tooltip
                title={t("common.orderByText", { orderCriteria: t(`common.last_execution_date`) })}
                placement="right-start"
              >
                <MenuItem onClick={() => handleOrderByDB("last_execution_date")}>
                  {t(`common.last_execution_date`)}
                  {orderByProject === "last_execution_date" && (
                    <div>
                      {orderByProjectList["last_execution_date"].desc ? (
                        <ArrowDownwardIcon style={{ color: "rgba(0, 0, 0, 0.3)" }} />
                      ) : (
                        <ArrowUpwardIcon style={{ color: "rgba(0, 0, 0, 0.3)" }} />
                      )}
                    </div>
                  )}
                </MenuItem>
              </Tooltip>
              <Tooltip
                title={t("common.orderByText", { orderCriteria: t(`common.name_project`) })}
                placement="right-start"
              >
                <MenuItem onClick={() => handleOrderByDB("name_project")}>
                  {t(`common.name_project`)}
                  {orderByProject === "name_project" && (
                    <div>
                      {orderByProjectList["name_project"].desc ? (
                        <ArrowDownwardIcon style={{ color: "rgba(0, 0, 0, 0.3)" }} />
                      ) : (
                        <ArrowUpwardIcon style={{ color: "rgba(0, 0, 0, 0.3)" }} />
                      )}
                    </div>
                  )}
                </MenuItem>
              </Tooltip>
            </Menu>
          </div>

          {listProject.map((project, index) => (
            <Accordion
              expanded={expanded === `panel${project.id_project}`}
              onChange={handleChangePanel(project.id_project)}
              className="accordionItem"
              key={index}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${project.id_project}bh-content`}
                id={`panel${project.id_project}bh-header`}
                className="accordionTitle"
              >
                <CheckboxCustom
                  sx={{
                    color: "#7024d1",
                    "&.Mui-checked": {
                      color: "#7024d1",
                    },
                  }}
                  handleChange={(_e: any) => handleCheckRemove(_e, index)}
                  value={project.id_project}
                  checked={project.checked}
                />
                <div className="container_accordionTitle">
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>{project.name_project}</Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    {t("common.creation_date")}: {project.creation_date} · {t("common.last_execution_date")}:{" "}
                    {project.last_execution_date ? project.last_execution_date : "--"}
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    {t("common.description")}: {project.description ? project.description : "--"}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="container_details">
                  <div>
                    <Tabs
                      onChange={handleChangeTab}
                      className="menu"
                      value={valueTab}
                      textColor="secondary"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#7024d1",
                        },
                      }}
                    >
                      {/*<Tab value="home" label="Home" />*/}
                      <Tab value="stats" label={t("projects.stats")} className="tabWidth" />
                      <Tab value="datasets" label="Datasets" className="tabWidth" />
                      <Tab value="comments" label={t("common.comments")} className="tabWidth" />
                    </Tabs>
                    {valueTab === "stats" && (
                      <div className="containerStats">
                        <TableC
                          rows={[
                            {
                              idProject: project.id_project,
                              state: project.state,
                              area: formatValue(project.area),
                              downloadAoi: true,
                            },
                          ]}
                          header={header}
                          keys={["state", "area", "downloadAoi"]}
                          downloadActive={downloadActive}
                          handleDownloadAoiClick={(fileFormat: string) => handleDownloadAoiClick(project, fileFormat)}
                          isSelected={project.id_project === selectedProject}
                        />
                      </div>
                    )}
                    {valueTab === "datasets" && (
                      <div className="containerStats">
                        <TableC
                          rows={listDataset}
                          header={headerDataset}
                          keys={["checkbox", "creation_date", "dataset_name", "state", "image_date", "inputImage"]}
                          handleChangeCheck={handleChangeCheck}
                          downloadActive={downloadActive}
                          handleDownloadAoiClick={(fileFormat: string) => handleDownloadAoiClick(project, fileFormat)}
                          isSelected={project.id_project === selectedProject}
                          showExtraParameters={datasetsWithParameters()}
                        />
                      </div>
                    )}
                    {valueTab === "comments" && (
                      <div className="containerStats">
                        <TableC
                          rows={project.comments}
                          header={["Comment", "Modified At"]}
                          keys={["comment", "modified_at"]}
                          isSelected={project.id_project === selectedProject}
                          updateProject={updateProject}
                          setSnackbarProps={setSnackbarProps}
                          setOpenSnackbar={setOpenSnackbar}
                        />
                        <ButtonCustom
                          label={t("projects.addComment")}
                          className="buttonStyleColor"
                          variant="contained"
                          handleClick={handleClickAddComment}
                          data={project.id_project}
                          tooltip={t("projects.addComment")}
                        />
                      </div>
                    )}
                  </div>
                  <div className="container_button">
                    <ButtonCustom
                      label={t("common.editButton")}
                      className="buttonStyleColor"
                      variant="contained"
                      handleClick={handleClickEditProject}
                      data={project.id_project}
                      tooltip={t("projects.editTooltip")}
                    />
                    <ButtonCustom
                      label={t("common.deleteButton")}
                      className={`${activeRemoveDataset ? "buttonStyleColor" : "buttonStyleColoOutline"}`}
                      variant="contained"
                      handleClick={() => handleClickRemoveDataset(project.id_project)}
                      data={project.id_project}
                      disabled={!activeRemoveDataset}
                      tooltip={t("projects.deleteDatasetTooltip")}
                    />
                    <ButtonCustom
                      label={t("common.executeButton")}
                      className={`${activeExecuteDataset ? "buttonStyleColor" : "buttonStyleColoOutline"}`}
                      variant="contained"
                      handleClick={() => handleOpenExecute(project.id_project)}
                      disabled={!activeExecuteDataset}
                      tooltip={t("projects.executeDatasetTooltip")}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination count={numberPages} page={page} onChange={handleChangePagination} />
          </div>
        </div>
      ) : (
        <div className="body_container">
          <Typography>{t("projects.emptyProjectList")}</Typography>
        </div>
      )}
      <CustomSnackbar snackbarProps={snackbarProps} openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} />
    </div>
  );
}

export default Projects;
