// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deck-tooltip {
  background-color: white !important;
  color: dimgrey !important;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 2px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

.custom-tooltip {
  background-color: transparent !important;
  color: dimgrey !important;
  border: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/map/style.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,yBAAA;EACA,sBAAA;EACA,aAAA;EACA,kBAAA;EACA,8BAAA;EACA,eAAA;EACA,yCAAA;EACA,oBAAA;AACF;;AAEA;EACE,wCAAA;EACA,yBAAA;EACA,sBAAA;AACF","sourcesContent":[".deck-tooltip {\n  background-color: white !important;\n  color: dimgrey !important;\n  border: 1px solid #ccc;\n  padding: 10px;\n  border-radius: 2px;\n  font-family: Arial, sans-serif;\n  font-size: 14px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  pointer-events: none;\n}\n\n.custom-tooltip {\n  background-color: transparent !important;\n  color: dimgrey !important;\n  border: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
