import React, { useState } from "react";
import {
  Button,
  IconButton,
  FormControl,
  InputAdornment,
  TextField,
  InputLabel,
  Link,
  Typography,
  FilledInput,
  Grid,
  Box,
  CircularProgress,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { userLogin } from "../../utils/requests";
import useStyles from "../../theme/styles";
import { useTranslation } from "react-i18next";
import { Imageryst11, Imageryst7 } from "../../icons/logo";
import { navigation } from "../../utils/navigation";
import { useDispatch } from "react-redux";
import { useAppSlice } from "../../slice";
import "./style.scss";

function Login() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actions } = useAppSlice();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [errorLogin, setErrorLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const { access_token, data } = await userLogin(email, password);

      dispatch(actions.setUser(data));

      localStorage.setItem("token", access_token);
      localStorage.setItem("token_access_imageryst", access_token);
      localStorage.setItem("id_user", data.id_user.toString());

      history.push(navigation.projects);
    } catch (e) {
      setErrorLogin(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid
        item
        xs={12}
        md={6}
        className="img_login_gradient"
        sx={{
          display: { xs: "none", md: "flex" },
        }}
      >
        <div>
          <Imageryst11 sx={{ width: "494px", height: "90px" }} />
          <div className="title_text">Analyze and monitor your assets from above</div>
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 4,
        }}
      >
        <Box
          component="form"
          sx={{
            width: "100%",
            maxWidth: 400,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
          onSubmit={handleLogin}
        >
          <Imageryst7 sx={{ height: "83px", width: "89px" }} />
          <TextField
            id="standard-required"
            value={email}
            label={t("login.email")}
            variant="filled"
            onChange={(e) => setEmail(e.target.value)}
            error={errorLogin}
            fullWidth
          />
          <FormControl>
            <InputLabel htmlFor="standard-adornment-password">{t("login.password")}</InputLabel>
            <FilledInput
              id="standard-adornment-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              className={`input ${classes.colorPassword}`}
              error={errorLogin}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={showPassword ? "hide the password" : "display the password"}
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(event) => event.preventDefault()}
                    onMouseUp={(event) => event.preventDefault()}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          {errorLogin && (
            <Typography variant="caption" display="block" gutterBottom color="red" className="errorLogin">
              {t("login.loginError")} <Link href="mailto:contact@imageryst.com">contact@imageryst.com</Link>
            </Typography>
          )}

          <Button variant="contained" className="btnLogin" type="submit">
            {isLoading ? <CircularProgress color="inherit" size={24} /> : t("common.confirmButton")}
          </Button>

          <Typography variant="caption" display="block" gutterBottom sx={{ textAlign: "center" }}>
            {t("login.createAccount")} <Link href="mailto:contact@imageryst.com">{t("login.contactUs")}</Link>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Login;
